import React from 'react';
import { Link } from 'react-router-dom';
import { EntityLabelEnum, NotesTagsEnum, ServiceTypeEnum, patchServiceRequest } from '@equips/entities-schema';
import { defaultServiceRequestSeverity, formatAddressAsOneLine } from '@equips/common-resources';
import { ArrayParam, JsonParam } from 'use-query-params';
import Avatar from '@mui/material/Avatar';
import { capitalize, toUpper } from 'lodash';
import dayjs from 'dayjs';
import { Translation } from 'react-i18next';
import Urls from '../../routes/Urls';
import { msToTime, toDateStringFromUnixMillisecondTimestamp, toUnixMillisecondsFromString } from '../../common/functions/dateFunctions';
import {
  getEnumValue,
  allowedNoteParentLabels,
  escalatedSeverityNumber,
  enumObjectToSelectOptions,
  serviceRequestSizeOptions,
  serviceRequestPriorityOptions,
  serviceRequestServiceTypes,
  getEnumDisplayName,
  clientOrganizationTypes,
  organizationIndustryTypes,
  requestOriginationOptions,
} from '../../graphql/enums';
import TableCellNotes from '../../common/components/DataTable/TableCellNotes';
import { getServiceRequestNoteList, severityOptions } from '../../graphql/queries/serviceRequestGraphQLQueries';
import DisplayTime from '../../common/components/Date/DisplayTime';
import { TableFilterTypes } from '../../common/components/DataTable/TableFilters';
import { standardNoteSerializerAndDeserializer } from '../../common/functions/standardSerializersAndDeserializers';
import { clientValueForFilter, providerValueForFilter } from '../../graphql/queries/organizationGraphQLQueries';
import { defaultColumnWidths } from '../../common/components/DataTable/columns';
import TableCellExpand from '../../common/components/DataTable/TableCellExpand';
import { TableCellExpandDangerouslySetInnerHTML } from '../../common/components/DataTable/TableCellExpandDangerouslySetInnerHTML';
import { centsToMoney } from '../../common/functions/moneyFunctions';
import TableCellCopyData from '../../common/components/DataTable/TableCellCopyData';
import { TableCellRelativeTime } from '../../common/components/DataTable/TableCellRelativeTime';
import { abbreviateName } from '../../common/functions/abbreviateName';
import AddressColumn from '../../common/components/Address/AddressColumn';
import { stripHtml } from '../../common/functions/stripHtml';
import { dateRangeOptions } from '../../common/components/Date/DateRangeFilter';
import TableCellInlineEditable from '../../common/components/DataTable/TableCellInlineEditable';
import { descriptionListInputTypes } from '../../common/components/ViewPage/DisplayDescriptionValue';
import { DisplayElapsedTime } from '../../common/components/Date/DisplayElaspedTime';
import { toDisplayPhoneFromPhoneWithCountryCode } from '../../common/functions/phoneFunctions';
import TierIndicator from '../organizations/components/TierIndicator';
import { invoiceStatuses } from '../invoices/invoiceStatuses';
import { determineHandleTimeForServiceRequest, getHandleTimeLabel } from './businessLogic/determineHandleTime';
import { ServiceRequestsRowActions } from './ServiceRequestsRowAction';
import OnHoldFollowUpEmail from './OnHoldFollowUpEmail';
import { getDowntime, getDowntimeString, isDowntimeInProgress } from './businessLogic/downtime';

export const metadataShortId = {
  id: 'metadata.shortId',
  Header: <Translation>{(t) => <>{t('number')}</>}</Translation>,
  accessor: (data) => data.metadata?.shortId,
  Cell: (data) => {
    return (
      <>
        <div className="flex justify-between">
          <Link
            data-testid="serviceRequestViewLink"
            className="active font-bold"
            to={`${Urls.SERVICE_REQUESTS}/${data.row.original?.metadata?.serviceRequestId}`}
          >
            {data.value}
          </Link>
          <ServiceRequestsRowActions {...data}></ServiceRequestsRowActions>
        </div>
      </>
    );
  },
  toText: (data) => data.value,
  filterOptions: {
    type: TableFilterTypes.text,
    placeholder: 'SR#',
  },
};

export const metadataTitle = {
  id: 'metadata.title',
  Header: <Translation>{(t) => <>{t('title')}</>}</Translation>,
  accessor: (data) => data.metadata?.title,
  width: defaultColumnWidths.largeText,
  toText: (d) => d?.value,
  Cell: (data) => (
    <TableCellInlineEditable
      {...data}
      formatter={(value) => <TableCellExpand data-testid="viewAllOfTitle">{value}</TableCellExpand>}
      onSave={(value) =>
        patchServiceRequest({ serviceRequestId: data.row.original?.metadata?.serviceRequestId, metadata: { title: value } })
      }
    />
  ),
};

export const metadataDescription = {
  id: 'metadata.description',
  Header: <Translation>{(t) => <>{t('description')}</>}</Translation>,
  accessor: (data) => data.metadata?.description,
  width: defaultColumnWidths.largeText,
  toText: (d) => stripHtml(d?.value),
  Cell: (d) => <TableCellExpandDangerouslySetInnerHTML data-testid="viewAllOfDescription" html={d.value} />,
};

export const metadataSize = {
  id: 'metadata.size',
  Header: <Translation>{(t) => <>{t('size')}</>}</Translation>,
  accessor: (data) => data.metadata?.size,
  width: defaultColumnWidths.extraSmall,
  toText: (d) => toUpper(d?.value),
  Cell: (data) => (
    <TableCellInlineEditable
      {...data}
      formatter={(value) => toUpper(value)}
      inputType={descriptionListInputTypes.select}
      options={enumObjectToSelectOptions(serviceRequestSizeOptions)}
      onSave={(value) =>
        patchServiceRequest({ serviceRequestId: data.row?.original?.metadata?.serviceRequestId, metadata: { size: value } })
      }
    />
  ),
  filterOptions: {
    type: TableFilterTypes.select,
    options: enumObjectToSelectOptions(serviceRequestSizeOptions, { anyText: 'Any', allowNull: true }),
  },
};

export const metadataPriority = {
  id: 'metadata.priority',
  Header: <Translation>{(t) => <>{t('priority')}</>}</Translation>,
  accessor: (data) => data.metadata?.priority,
  width: defaultColumnWidths.extraSmall,
  toText: (d) => capitalize(d?.value),
  Cell: (data) => (
    <TableCellInlineEditable
      {...data}
      formatter={(value) => capitalize(value)}
      inputType={descriptionListInputTypes.select}
      options={enumObjectToSelectOptions(serviceRequestPriorityOptions)}
      onSave={(value) =>
        patchServiceRequest({ serviceRequestId: data.row?.original?.metadata?.serviceRequestId, metadata: { priority: value } })
      }
    />
  ),
  filterOptions: {
    type: TableFilterTypes.multiSelect,
    options: enumObjectToSelectOptions(serviceRequestPriorityOptions, { anyText: 'Any', allowNull: true }),
  },
};

export const symptoms = {
  id: 'symptoms',
  Header: <Translation>{(t) => <>{t('symptoms')}</>}</Translation>,
  accessor: (data) => {
    return data?.incidents?.[0]?.metadata?.symptoms;
  },
  toText: (d) => d.value?.join(', ') ?? '',
  Cell: (d) => d.value?.join(', ') ?? '',
  width: defaultColumnWidths.largeText,
  disableSortBy: true,
};

export const metadataChecklist = {
  id: 'metadata.checklist.metadata.completedPercentage',
  Header: <Translation>{(t) => <>{t('checklist')}</>}</Translation>,
  width: defaultColumnWidths.extraSmall,
  accessor: (data) => {
    return data?.metadata?.checklist?.metadata?.completedPercentage;
  },
  toText: (data) => (data.row.original?.metadata?.checklist?.metadata?.checklistId ? `${Math.round(data.value)}%` : ''),
  Cell: (data) => (data.row.original?.metadata?.checklist?.metadata?.checklistId ? `${Math.round(data.value)}%` : ''),
  disableSortBy: true,
};

export const downtime = {
  id: 'downtime',
  Header: <Translation>{(t) => <>{t('downtime')}</>}</Translation>,
  accessor: (data) => data.metadata?.createdAt,
  width: defaultColumnWidths.extraSmall,
  disableSortBy: true,
  toText: (data) => {
    const metadata = data.row.original?.metadata || {};
    return getDowntimeString(metadata);
  },
  Cell: (data) => {
    const metadata = data.row.original?.metadata || {};
    const value = getDowntime(metadata);
    const inProgress = isDowntimeInProgress(metadata);
    return <DisplayElapsedTime value={value} inProgress={inProgress} />;
  },
};

export const metadataCompletedAt = {
  id: 'metadata.completedAt',
  Header: <Translation>{(t) => <>{t('completedAt')}</>}</Translation>,
  accessor: (data) => data.metadata?.completedAt,
  width: defaultColumnWidths.smallText,
  toText: (data) => {
    const time = toDateStringFromUnixMillisecondTimestamp(data?.value, {
      customFormatString: 'M/D/YY h:mm a',
    });
    return time;
  },
  Cell: (data) => {
    return <DisplayTime timestamp={data?.value} />;
  },
  filterOptions: {
    type: TableFilterTypes.dateRange,
  },
};

export const metadataCompletedBy = {
  id: 'metadata.completedBy',
  Header: <Translation>{(t) => <>{t('completedBy')}</>}</Translation>,
  accessor: (data) => data.metadata?.completedByUser?.metadata?.fullName,
  width: defaultColumnWidths.smallText,
  Cell: (data) => {
    return <>{data?.value}</>;
  },
};

export const metadataDueDate = {
  id: 'metadata.dueDate',
  Header: <Translation>{(t) => <>{t('dueDate')}</>}</Translation>,
  accessor: (data) => data.metadata?.dueDate,
  width: defaultColumnWidths.smallText,
  toText: (data) => {
    return toDateStringFromUnixMillisecondTimestamp(data?.value);
  },
  Cell: (data) => {
    return (
      <TableCellInlineEditable
        {...data}
        value={toDateStringFromUnixMillisecondTimestamp(data?.value)}
        formatter={(value) => (value ? dayjs(value).format('MM/DD/YYYY') : '')}
        inputType={descriptionListInputTypes.date}
        onSave={(value) => {
          patchServiceRequest({
            serviceRequestId: data.row?.original?.metadata?.serviceRequestId,
            metadata: { dueDate: toUnixMillisecondsFromString(value) },
          });
        }}
      />
    );
  },
  filterOptions: {
    type: TableFilterTypes.biDirectionalDateRange,
    options: [
      dateRangeOptions.next7,
      dateRangeOptions.tomorrow,
      dateRangeOptions.today,
      dateRangeOptions.yesterday,
      dateRangeOptions.last7,
      dateRangeOptions.none,
      dateRangeOptions.notEmpty,
      dateRangeOptions.custom,
    ],
  },
};

export const metadataClosedAt = {
  id: 'metadata.closedAt',
  Header: <Translation>{(t) => <>{t('closedAt')}</>}</Translation>,
  accessor: (data) => data.metadata?.closedAt,
  width: defaultColumnWidths.smallText,
  toText: (data) => {
    const time = toDateStringFromUnixMillisecondTimestamp(data?.value, {
      customFormatString: 'M/D/YY h:mm a',
    });
    return time;
  },
  Cell: (data) => {
    return <DisplayTime timestamp={data?.value} />;
  },
  filterOptions: {
    type: TableFilterTypes.dateRange,
  },
};

export const metadataClosedBy = {
  id: 'metadata.closedBy',
  Header: <Translation>{(t) => <>{t('closedBy')}</>}</Translation>,
  accessor: (data) => data.metadata?.closedByUser?.metadata?.fullName,
  width: defaultColumnWidths.smallText,
  Cell: (data) => {
    return <>{data?.value}</>;
  },
};

export const closed = {
  id: 'closed',
  Header: <Translation>{(t) => <>{t('closed')}</>}</Translation>,
  accessor: (data) => data.metadata?.closedAt,
  Cell: (data) => {
    return <>{data.row.original?.metadata?.closedAt ? data.t('closed') : data.t('notClosed')}</>;
  },
  filterOptions: {
    type: TableFilterTypes.select,
    options: [
      { label: 'All', value: 'all' },
      { label: 'Not Closed', value: 'notClosed' },
      { label: 'Closed', value: 'closed' },
    ],
  },
};

export const metadatascheduledDate = {
  id: 'metadata.scheduledDate',
  Header: <Translation>{(t) => <>{t('scheduledDate')}</>}</Translation>,
  accessor: (data) => data.metadata?.scheduledDate,
  toText: (data) => toDateStringFromUnixMillisecondTimestamp(data?.value, { customFormatString: 'MM/DD/YYYY hh:mma' }),
  Cell: (data) => {
    return (
      <TableCellInlineEditable
        {...data}
        value={data?.value}
        formatter={(value) => {
          const dateTimeString = toDateStringFromUnixMillisecondTimestamp(data?.value, { customFormatString: 'MM/DD/YYYY hh:mma' }) || '';
          const displayValue = value ? (value !== dateTimeString ? dayjs(value)?.format('MM/DD/YYYY hh:mma') : dateTimeString) : '';

          return <>{displayValue}</>;
        }}
        inputType={descriptionListInputTypes.datetime}
        onSave={async (value) => {
          const timestamp = toUnixMillisecondsFromString(value);

          await patchServiceRequest({
            serviceRequestId: data.row?.original?.metadata?.serviceRequestId,
            metadata: { scheduledDate: timestamp },
          });

          data.refetchData();
        }}
      />
    );
  },
  filterOptions: {
    type: TableFilterTypes.dateRange,
    options: [
      dateRangeOptions.today,
      dateRangeOptions.yesterday,
      dateRangeOptions.last7,
      dateRangeOptions.last30,
      dateRangeOptions.last90,
      dateRangeOptions.none,
      dateRangeOptions.notEmpty,
      dateRangeOptions.custom,
    ],
  },
};

export const metadatarecurrenceInterval = {
  id: 'metadata.recurrenceInterval',
  Header: <Translation>{(t) => <>{t('recurrenceInterval')}</>}</Translation>,
  accessor: (data) => data.metadata?.recurrenceInterval,
  disableSortBy: true,
  width: 150,
  Cell: (data) =>
    data?.row?.original?.metadata?.serviceType !== ServiceTypeEnum.Preventive
      ? null
      : data.value
      ? data.t('everyNDays', { count: data.value })
      : data.t('once'),
  filterOptions: {
    type: TableFilterTypes.recurrenceInterval,
  },
};

export const metadatarecurrenceOption = {
  id: 'metadata.recurrenceOption',
  Header: <Translation>{(t) => <>{t('recurrenceOption')}</>}</Translation>,
  accessor: (data) => data.metadata?.recurrenceOption,
  disableSortBy: true,
  width: 150,
  Cell: (data) => {
    const serviceType = data?.row?.original?.metadata?.serviceType;
    const recurrenceOption = data?.value;

    if (serviceType === ServiceTypeEnum.Preventive || !recurrenceOption) return '';
    if (serviceType === ServiceTypeEnum.Corrective) {
      return capitalize(recurrenceOption);
    }
    return 'N/A';
  },
  filterOptions: {
    type: TableFilterTypes.recurrenceOption,
  },
};

export const metadatafailureDate = {
  id: 'metadata.failureDate',
  Header: <Translation>{(t) => <>{t('failureDate')}</>}</Translation>,
  accessor: (data) => data.metadata?.failureDate,
  width: defaultColumnWidths.smallText,
  toText: (data) => {
    return toDateStringFromUnixMillisecondTimestamp(data?.value);
  },
  Cell: (data) => {
    return (
      <TableCellInlineEditable
        {...data}
        value={toDateStringFromUnixMillisecondTimestamp(data?.value)}
        formatter={(value) => (value ? dayjs(value).format('MM/DD/YYYY') : '')}
        inputType={descriptionListInputTypes.date}
        onSave={(value) => {
          patchServiceRequest({
            serviceRequestId: data.row?.original?.metadata?.serviceRequestId,
            metadata: { dueDate: toUnixMillisecondsFromString(value) },
          });
        }}
      />
    );
  },
  filterOptions: {
    type: TableFilterTypes.dateRange,
  },
};

export const metadataclientFollowUpDate = {
  id: 'metadata.clientFollowUpDate',
  Header: <Translation>{(t) => <>{t('followUpDate')}</>}</Translation>,
  accessor: (data) => data.metadata?.clientFollowUpDate,
  width: defaultColumnWidths.mediumText,
  toText: (data) => toDateStringFromUnixMillisecondTimestamp(data?.value, { customFormatString: 'MM/DD/YYYY hh:mma' }),
  Cell: (data) => {
    return (
      <TableCellInlineEditable
        {...data}
        value={data?.value}
        formatter={(value) => <TableCellRelativeTime timestamp={value} />}
        inputType={descriptionListInputTypes.datetime}
        onSave={async (value) => {
          const timestamp = toUnixMillisecondsFromString(value);

          await patchServiceRequest({
            serviceRequestId: data.row?.original?.metadata?.serviceRequestId,
            metadata: { clientFollowUpDate: timestamp },
          });

          data.refetchData();
        }}
      />
    );
  },
  filterOptions: {
    type: TableFilterTypes.dateRange,
  },
};

export const metadatafollowUpDate = {
  id: 'metadata.followUpDate',
  Header: <Translation>{(t) => <>{t('equipsFollowUpDate')}</>}</Translation>,
  accessor: (data) => data.metadata?.followUpDate,
  width: defaultColumnWidths.mediumText,
  toText: (data) => toDateStringFromUnixMillisecondTimestamp(data?.value, { customFormatString: 'MM/DD/YYYY hh:mma' }),
  Cell: (data) => {
    return (
      <TableCellInlineEditable
        {...data}
        value={data?.value}
        formatter={(value) => {
          const dateTimeString =
            toDateStringFromUnixMillisecondTimestamp(data?.value, { customFormatString: 'MM/DD/YYYY hh:mma' }) || 'None';

          return (
            <>
              {value ? (value !== dateTimeString ? dayjs(value)?.format('MM/DD/YYYY hh:mma') : dateTimeString) : ''}
              <span className="ml-2 text-equipsLightBlue">
                <OnHoldFollowUpEmail {...data.row.original} />
              </span>
            </>
          );
        }}
        inputType={descriptionListInputTypes.datetime}
        onSave={async (value) => {
          const timestamp = toUnixMillisecondsFromString(value);

          await patchServiceRequest({
            serviceRequestId: data.row?.original?.metadata?.serviceRequestId,
            metadata: { followUpDate: timestamp },
          });

          data.refetchData();
        }}
      />
    );
  },
  filterOptions: {
    type: TableFilterTypes.dateRange,
  },
};

export const dispatchStatus = {
  id: 'dispatchStatus',
  Header: <Translation>{(t) => <>{t('dispatchStatus')}</>}</Translation>,
  accessor: (data) => {
    return data?.metadata?.providerId;
  },
  toText: (data) => {
    return data?.value ? data.t('dispatchedExternally') : data.t('inHouse');
  },
  Cell: (data) => {
    return data?.value ? data.t('dispatchedExternally') : data.t('inHouse');
  },
  width: defaultColumnWidths.mediumText,
  disableSortBy: true,
  filterOptions: {
    type: TableFilterTypes.select,
    options: [
      { label: 'All', value: 'all' },
      { label: 'In-House', value: 'inHouse' },
      { label: 'Dispatched Externally', value: 'dispatchedExternally' },
    ],
  },
};

export const specMetadataCategoryId = {
  id: 'specMetadata.categoryId',
  Header: <Translation>{(t) => <>{t('category')}</>}</Translation>,
  accessor: (data) => data.metadata?.equipment?.specMetadata?.categoryId,
  Cell: (data) => data.row?.original?.metadata?.equipment?.specMetadata?.category?.metadata?.name || '',
  filterOptions: {
    type: TableFilterTypes.categorySelect,
    defaultValue: [],
    filterType: ArrayParam,
  },
  width: defaultColumnWidths.normalText,
};

export const equipmentMetadataSubclasses = {
  id: 'equipmentMetadata.subclasses',
  Header: <Translation>{(t) => <>{t('subcategory')}</>}</Translation>,
  accessor: (data) => data.metadata?.equipment?.metadata?.subclasses,
  disableSortBy: true,
  Cell: (data) => {
    return (data?.original?.equipmentMetadata?.subclasses || []).join(', ');
  },
  filterOptions: {
    type: TableFilterTypes.subcategorySelect,
    defaultValue: [],
    filterType: JsonParam,
  },
  width: defaultColumnWidths.normalText,
};

export const equipmentMetadataCreatedAt = {
  id: 'equipmentMetadata.createdAt',
};

export const equipmentCoverageMetadataStatus = {
  id: 'coverageMetadata.coverageStatus',
};

export const specMetadataManufacturer = {
  id: 'specMetadata.manufacturer',
  Header: <Translation>{(t) => <>{t('manufacturer')}</>}</Translation>,
  accessor: (data) => data.metadata?.equipment?.specMetadata?.manufacturer,
  width: defaultColumnWidths.normalText,
};

export const specMetadataModel = {
  id: 'specMetadata.model',
  Header: <Translation>{(t) => <>{t('model')}</>}</Translation>,
  accessor: (data) => data.metadata?.equipment?.specMetadata?.model,
  width: defaultColumnWidths.normalText,
};

export const specMetadataManufacturerEOL = {
  id: 'specMetadata.manufacturerEOL',
  Header: <Translation>{(t) => <>{t('manufacturerEol')}</>}</Translation>,
  accessor: (data) => data.metadata?.equipment?.specMetadata?.manufacturerEOL,
  Cell: (data) => toDateStringFromUnixMillisecondTimestamp(data?.value),
  width: defaultColumnWidths.normalText,
  filterOptions: {
    type: TableFilterTypes.dateRange,
  },
};

export const specMetadataVendorLimitedSupportDate = {
  id: 'specMetadata.vendorLimitedSupportDate',
  Header: <Translation>{(t) => <>{t('vendorLimitedSupport')}</>}</Translation>,
  accessor: (data) => data.metadata?.equipment?.specMetadata?.vendorLimitedSupportDate,
  Cell: (data) => toDateStringFromUnixMillisecondTimestamp(data?.value),
  width: defaultColumnWidths.normalText,
  filterOptions: {
    type: TableFilterTypes.dateRange,
  },
};

export const metadataEquipmentId = {
  id: 'metadata.equipmentId',
  Header: <Translation>{(t) => <>{t('equipmentId')}</>}</Translation>,
  accessor: (data) => data.metadata?.equipmentId,
  width: defaultColumnWidths.smallText,
  disableSortBy: true,
  filterOptions: {
    type: TableFilterTypes.text,
  },
};

export const equipmentMetadataShortId = {
  id: 'equipmentMetadata.shortId',
  Header: <Translation>{(t) => <>{t('equipmentNo')}</>}</Translation>,
  accessor: (data) => data.equipmentMetadata?.shortId,
  width: defaultColumnWidths.smallText,
  disableSortBy: true,
  filterOptions: {
    type: TableFilterTypes.text,
    placeholder: 'EQ#',
  },
};

export const metadataEquipmentName = {
  id: 'metadata.equipment.metadata.equipmentName',
  Header: <Translation>{(t) => <>{t('equipmentName')}</>}</Translation>,
  accessor: (data) => data.metadata?.equipment?.metadata?.equipmentName,
  width: defaultColumnWidths.normalText,
  disableSortBy: true,
};

export const equipmentMetadataExternalId = {
  id: 'equipmentMetadata.externalId',
  Header: <Translation>{(t) => <>{t('equipmentExternalId')}</>}</Translation>,
  accessor: (data) => data.equipmentMetadata?.externalId,
  width: defaultColumnWidths.normalText,
  disableSortBy: true,
};

export const metadataAasContractId = {
  Header: 'AAS Contract',
  id: 'metadata.aasContractId',
  accessor: (data) => data?.metadata?.aasContractId,
  Cell: (data) => (
    <Link className="active font-bold" to={`${Urls.AAS_CONTRACTS}/${data.value}`}>
      {data.row?.original?.metadata?.aasContract?.metadata?.homeContractNo}
    </Link>
  ),
  disableSortBy: true,
};

export const locationMetadataShortId = {
  Header: <Translation>{(t) => <>{t('locationNo')}</>}</Translation>,
  id: 'locationMetadata.shortId',
  accessor: (data) => data.locationMetadata?.shortId,
  width: defaultColumnWidths.smallText,
  disableSortBy: true,
};

export const metadataLocationId = {
  id: 'metadata.locationId',
  Header: <Translation>{(t) => <>{t('location')}</>}</Translation>,
  accessor: (data) => data.locationMetadata?.locationDisplayName,
  width: defaultColumnWidths.normalText,
  filterOptions: {
    type: TableFilterTypes.locationSelect,
  },
};

export const metadataLocationAddressLine1 = {
  id: 'metadata.location.address.line1',
  Header: <Translation>{(t) => <>{t('address')}</>}</Translation>,
  accessor: (data) => data?.metadata?.location?.address?.line1,
  width: defaultColumnWidths.mediumText,
  disableSortBy: true,
  Cell: (d) => <AddressColumn addressObject={d.row.original?.metadata?.location?.address} />,
  toText: (d) => formatAddressAsOneLine(d.row.original?.metadata?.location?.address),
};

export const metadataLocationAddressCity = {
  id: 'metadata.location.address.city',
  Header: <Translation>{(t) => <>{t('city')}</>}</Translation>,
  accessor: (data) => data?.metadata?.location?.address?.city,
  disableSortBy: true,
};

export const metadataLocationAddressState = {
  id: 'metadata.location.address.state',
  Header: <Translation>{(t) => <>{t('state')}</>}</Translation>,
  accessor: (data) => data?.metadata?.location?.address?.stateUnabbreviated,
  disableSortBy: true,
};

export const metadataLocationAddressZip = {
  id: 'metadata.location.address.zip',
  Header: <Translation>{(t) => <>{t('zip')}</>}</Translation>,
  accessor: (data) => data?.metadata?.location?.address?.zip,
  width: defaultColumnWidths.extraSmall,
  disableSortBy: true,
};

export const metadataLocationDisplayName = {
  id: 'metadata.location.metadata.parentLocation.metadata.locationDisplayName',
  Header: <Translation>{(t) => <>{t('parentLocation')}</>}</Translation>,
  accessor: (data) => data?.metadata?.location?.metadata?.parentLocation?.metadata?.locationDisplayName,
  disableSortBy: true,
};

export const metadataProviderId = {
  id: 'metadata.providerId',
  Header: <Translation>{(t) => <>{t('provider')}</>}</Translation>,
  accessor: (data) => data.providerMetadata?.organizationName,
  width: defaultColumnWidths.normalText,
  disableSortBy: true,
  filterOptions: {
    type: TableFilterTypes.organizationSelect,
    typeOfOrganizationToFind: providerValueForFilter,
    allowNullNotNull: true,
  },
};

export const metadataAssignedInternalUserId = {
  id: 'metadata.assignedInternalUserId',
  Header: <Translation>{(t) => <>{t('assignedInternalUser')}</>}</Translation>,
  accessor: (data) => data?.metadata?.assignedInternalUser?.metadata?.fullName,
  toText: (data) => data?.value,
  Cell: (data) => {
    return (
      <TableCellInlineEditable
        {...data}
        inputType={descriptionListInputTypes.inlineUserSelect}
        formatter={(_value) => {
          const fullName = data.row?.original?.metadata?.assignedInternalUser?.metadata?.fullName;
          const url = data.row?.original?.metadata?.assignedInternalUser?.metadata?.profilePictureUrl;
          if (fullName) {
            return (
              <div className="flex items-center gap-2">
                <Avatar src={url ?? ''} alt={fullName ?? data.t('unknown')} className="h-8 w-8 bg-blue-700">
                  {abbreviateName(fullName)}
                </Avatar>
                {fullName}
              </div>
            );
          } else {
            return <Translation>{(t) => <>{t('unassigned')}</>}</Translation>;
          }
        }}
        onSave={async (_value) => {
          data.refetchData();
        }}
        entityLabel={EntityLabelEnum.ServiceRequest}
        entityId={data?.row?.original?.metadata?.serviceRequestId}
        fieldToPatch="assignedInternalUserId"
      />
    );
  },
  filterOptions: {
    type: TableFilterTypes.userSelect,
    showOnMobile: true,
  },
};

export const metadataMainContactUserId = {
  id: 'metadata.mainContactUserId',
  Header: <Translation>{(t) => <>{t('mainContact')}</>}</Translation>,
  accessor: (data) => {
    return data?.metadata?.mainContactUser?.metadata?.fullName;
  },
  toText: (data) => data?.value,
  Cell: (data) => {
    const fullName = data.row?.original?.metadata?.mainContactUser?.metadata?.fullName;
    const url = data.row?.original?.metadata?.mainContactUser?.metadata?.profilePictureUrl;
    if (fullName) {
      return (
        <div className="flex items-center gap-2">
          <Avatar src={url ?? ''} alt={fullName ?? data.t('unknown')} className="h-8 w-8 bg-blue-700">
            {abbreviateName(fullName)}
          </Avatar>
          {fullName}
        </div>
      );
    } else {
      return <Translation>{(t) => <>{t('unassigned')}</>}</Translation>;
    }
  },
  filterOptions: {
    type: TableFilterTypes.userSelect,
    showOnMobile: true,
  },
};

export const metadataMainContactPhoneNumberWithCountryCode = {
  id: 'metadata.mainContact.phoneNumberWithCountryCode',
  Header: <Translation>{(t) => <>{t('mainContactPhone')}</>}</Translation>,
  disableSortBy: true,
  accessor: (data) => toDisplayPhoneFromPhoneWithCountryCode(data?.metadata?.mainContactUser?.metadata?.phoneNumberWithCountryCode),
};

export const metadataMainContactEmail = {
  id: 'metadata.mainContact.email',
  Header: <Translation>{(t) => <>{t('mainContactEmail')}</>}</Translation>,
  disableSortBy: true,
  accessor: (data) => data?.metadata?.mainContactUser?.metadata?.email,
};

export const metadataProviderRefId = {
  id: 'metadata.providerRefId',
  Header: <Translation>{(t) => <>{t('providerId')}</>}</Translation>,
  accessor: (data) => data.metadata?.providerRefId,
  width: defaultColumnWidths.normalText,
};

export const metadataServiceType = {
  id: 'metadata.serviceType',
  Header: <Translation>{(t) => <>{t('serviceType')}</>}</Translation>,
  accessor: (data) => data.metadata?.serviceType,
  filterOptions: {
    type: TableFilterTypes.multiSelect,
    defaultValue: [],
    filterType: JsonParam,
    options: enumObjectToSelectOptions(serviceRequestServiceTypes),
  },
  width: defaultColumnWidths.normalText,
  Cell: (data) => getEnumDisplayName(data.value, serviceRequestServiceTypes),
};

export const metadataSeverity = {
  id: 'metadata.severity',
  Header: <Translation>{(t) => <>{t('escalated')}</>}</Translation>,
  accessor: (data) => data.metadata?.severity,
  filterOptions: {
    type: TableFilterTypes.select,
    options: [
      { label: 'Any', value: severityOptions.any },
      { label: 'Escalated', value: severityOptions.escalated },
      { label: 'Not escalated', value: severityOptions.notEscalated },
    ],
  },
  width: defaultColumnWidths.normalText,
  Cell: (data) =>
    (data?.value || defaultServiceRequestSeverity) >= escalatedSeverityNumber ? (
      <Translation>{(t) => <>{t('escalated')}</>}</Translation>
    ) : (
      ''
    ),
};

export const metadataPotentiallyChronic = {
  id: 'metadata.potentiallyChronic',
  Header: <Translation>{(t) => <>{t('potentiallyChronic')}</>}</Translation>,
  accessor: (data) => data.metadata?.potentiallyChronic,
  disableSortBy: true,
  filterOptions: {
    type: TableFilterTypes.select,
    options: [
      { label: 'Any', value: '' },
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' },
    ],
  },
  width: defaultColumnWidths.normalText,
  Cell: (data) => (data.value ? data.t('yes') : data.t('no')),
};

export const equipmentMetadataHasChronicIssues = {
  id: 'equipmentMetadata.hasChronicIssues',
  Header: <Translation>{(t) => <>{t('chronicIssues')}</>}</Translation>,
  accessor: (data) => data.equipmentMetadata?.hasChronicIssues,
  disableSortBy: true,
  filterOptions: {
    type: TableFilterTypes.select,
    options: [
      { label: 'Any', value: '' },
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' },
    ],
  },
  width: defaultColumnWidths.normalText,
  Cell: (data) => (data.value ? data.t('yes') : data.t('no')),
};

export const metadataAdditionalPmCompleted = {
  id: 'metadata.additionalPmCompleted',
  Header: <Translation>{(t) => <>{t('pmCompleted')}</>}</Translation>,
  accessor: (data) => data.metadata?.additionalPmCompleted,
  disableSortBy: true,
  filterOptions: {
    type: TableFilterTypes.select,
    options: [
      { label: 'Any', value: '' },
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' },
    ],
  },
  width: defaultColumnWidths.normalText,
  Cell: (data) => (data.value ? data.t('yes') : data.t('no')),
};

export const metadataCheckedInDuration = {
  id: 'metadata.checkedInDuration',
  Header: <Translation>{(t) => <>{t('checkedInDuration')}</>}</Translation>,
  accessor: (data) => data.metadata?.checkedInDuration,
  disableSortBy: true,
  Cell: (data) => {
    return <>{msToTime(data.value)}</>;
  },
  toText: (data) => msToTime(data.value),
};

export const metadataBillable = {
  id: 'metadata.billable',
  Header: <Translation>{(t) => <>{t('billable')}</>}</Translation>,
  accessor: (data) => data.metadata?.billable,
  width: defaultColumnWidths.extraSmall,
  filterOptions: {
    type: TableFilterTypes.select,
    options: [
      { label: 'Any', value: '' },
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' },
    ],
  },
  Cell: (data) => {
    return (
      <TableCellInlineEditable
        {...data}
        value={data.value ? 'Yes' : 'No'}
        inputType={descriptionListInputTypes.select}
        options={[
          { label: data.t('yes'), value: 'Yes' },
          { label: data.t('no'), value: 'No' },
        ]}
        onSave={(value) => {
          patchServiceRequest({
            serviceRequestId: data.row?.original?.metadata?.serviceRequestId,
            metadata: { billable: value === 'Yes' ? true : false },
          });
        }}
      />
    );
  },
};

export const metadataExternalWorkOrderNo = {
  id: 'metadata.externalWorkOrderNo',
  Header: <Translation>{(t) => <>{t('externalWorkOrderNo')}</>}</Translation>,
  accessor: (data) => data.metadata?.externalWorkOrderNo,
  width: defaultColumnWidths.normalText,
};

export const metadataPoAmount = {
  id: 'metadata.poAmount',
  Header: <Translation>{(t) => <>{t('poAmount')}</>}</Translation>,
  accessor: (data) => data.metadata?.poAmount,
  width: defaultColumnWidths.smallText,
  toText: (data) => centsToMoney(data.value || 0),
  Cell: (data) => (
    <TableCellInlineEditable
      {...data}
      inputType={descriptionListInputTypes.currency}
      formatter={(value) => centsToMoney(value)}
      onSave={(value) =>
        patchServiceRequest({
          serviceRequestId: data.row?.original?.metadata?.serviceRequestId,
          metadata: { poAmount: value },
        })
      }
    />
  ),
  filterOptions: {
    type: TableFilterTypes.currencyRange,
  },
};

export const invoiceMetadataInvoiceTotal = {
  id: 'invoiceMetadata.invoiceTotal',
  Header: <Translation>{(t) => <>{t('invoiceTotal')}</>}</Translation>,
  accessor: (data) => data.invoiceMetadata?.invoiceTotal,
  Cell: (data) => centsToMoney(data?.row?.original?.invoiceMetadata?.invoiceTotal),
};

export const invoiceMetadataInvoiceStatus = {
  id: 'invoiceMetadata.invoiceStatus',
  Header: <Translation>{(t) => <>{t('invoiceStatus')}</>}</Translation>,
  accessor: (data) => data.invoiceMetadata?.invoiceStatus,
  toText: (data) => getEnumDisplayName(data.value, invoiceStatuses),
  Cell: (data) => getEnumDisplayName(data.value, invoiceStatuses),
};

export const metadataVariance = {
  id: 'metadata.variance',
  Header: <Translation>{(t) => <>{t('variance')}</>}</Translation>,
  width: defaultColumnWidths.extraSmall,
  disableSortBy: true,
  accessor: (data) => data?.metadata?.variance,
  Cell: (data) =>
    typeof data?.value === 'number' && <span className={`${data?.value < 0 && 'text-red-700'}`}>{centsToMoney(data?.value)}</span>,
};

export const metadataNonCoverageInvoiceNumber = {
  id: 'metadata.nonCoverageInvoiceNumber',
  Header: <Translation>{(t) => <>{t('externalInvoiceNo')}</>}</Translation>,
  accessor: (data) => data.metadata?.nonCoverageInvoiceNumber,
  disableSortBy: true,
  filterOptions: {
    type: TableFilterTypes.select,
    options: [
      { label: 'All', value: 'all' },
      { label: 'Has external invoice number', value: 'notBlank' },
      { label: 'No external invoice number', value: 'blank' },
    ],
  },
  toText: (data) => data.value,
  Cell: (data) => (
    <TableCellInlineEditable
      {...data}
      inputType={descriptionListInputTypes.input}
      onSave={(value) =>
        patchServiceRequest({
          serviceRequestId: data.row?.original?.metadata?.serviceRequestId,
          metadata: { nonCoverageInvoiceNumber: value || null },
        })
      }
    />
  ),
};

export const metadataNonCoverageInvoiceTotal = {
  id: 'metadata.nonCoverageInvoiceTotal',
  Header: <Translation>{(t) => <>{t('externalInvoiceTotal')}</>}</Translation>,
  accessor: (data) => data.metadata?.nonCoverageInvoiceTotal,
  toText: (data) => centsToMoney(data?.value),
  Cell: (data) => (
    <TableCellInlineEditable
      {...data}
      inputType={descriptionListInputTypes.currency}
      formatter={(value) => centsToMoney(value)}
      onSave={(value) =>
        patchServiceRequest({
          serviceRequestId: data.row?.original?.metadata?.serviceRequestId,
          metadata: { nonCoverageInvoiceTotal: value },
        })
      }
    />
  ),
  filterOptions: {
    type: TableFilterTypes.select,
    options: [
      { label: 'All', value: 'all' },
      { label: 'Has external invoice total', value: 'notBlank' },
      { label: 'No external invoice total', value: 'blank' },
    ],
  },
};

export const metadataOrganizationId = {
  id: 'metadata.organizationId',
  Header: <Translation>{(t) => <>{t('organization')}</>}</Translation>,
  accessor: (data) => data.organizationMetadata?.organizationName,
  Cell: (data) => (
    <>
      {data?.value} <TierIndicator tier={data?.row?.original?.organizationMetadata?.tier} />
    </>
  ),
  width: defaultColumnWidths.normalText,
  filterOptions: {
    type: TableFilterTypes.organizationMultiSelect,
    defaultValue: [],
    typeOfOrganizationToFind: clientValueForFilter,
    filterType: JsonParam,
  },
};

export const organizationMetadataClientOrganizationType = {
  id: 'organizationMetadata.clientOrganizationType',
  Header: <Translation>{(t) => <>{t('clientOrganizationType')}</>}</Translation>,
  accessor: (data) => data.organizationMetadata?.clientOrganizationType,
  Cell: (d) => getEnumDisplayName(d?.value, clientOrganizationTypes) || '',
  toText: (d) => getEnumDisplayName(d?.value, clientOrganizationTypes) || '',
  filterOptions: {
    type: TableFilterTypes.select,
    options: enumObjectToSelectOptions(clientOrganizationTypes, { anyText: 'Any' }),
  },
};

export const organizationMetadataIndustry = {
  id: 'organizationMetadata.industry',
  Header: <Translation>{(t) => <>{t('industry')}</>}</Translation>,
  accessor: (data) => data.organizationMetadata?.industry,
  Cell: (d) => getEnumDisplayName(d?.value, organizationIndustryTypes) || '',
  toText: (d) => getEnumDisplayName(d?.value, organizationIndustryTypes) || '',
  filterOptions: {
    type: TableFilterTypes.select,
    options: enumObjectToSelectOptions(organizationIndustryTypes, { anyText: 'Any' }),
  },
};

export const metadataRequestOrigination = {
  id: 'metadata.requestOrigination',
  Header: <Translation>{(t) => <>{t('origination')}</>}</Translation>,
  accessor: (data) => data.metadata?.requestOrigination,
  filterOptions: {
    type: TableFilterTypes.multiSelect,
    options: enumObjectToSelectOptions(requestOriginationOptions),
    defaultValue: [],
    filterType: JsonParam,
  },
  width: defaultColumnWidths.normalText,
  Cell: (data) => getEnumDisplayName(data.value, requestOriginationOptions),
};

export const metadataDispatchedToProviderAt = {
  id: 'metadata.dispatchedToProviderAt',
  Header: <Translation>{(t) => <>{t('handleTime')}</>}</Translation>,
  accessor: (data) => data.metadata?.dispatchedToProviderAt,
  disableSortBy: true,
  Cell: (d) => {
    const { createdAt, dispatchedInternallyAt, dispatchedToEquipsAt, dispatchedToProviderAt, requestStatus } =
      d.row.original?.metadata || {};
    const handleTime = determineHandleTimeForServiceRequest({
      createdAt,
      dispatchedInternallyAt,
      dispatchedToEquipsAt,
      dispatchedToProviderAt,
    });

    return <>{getHandleTimeLabel({ handleTime, requestStatus })}</>;
  },
  toText: (d) => {
    const { createdAt, dispatchedInternallyAt, dispatchedToEquipsAt, dispatchedToProviderAt, requestStatus } =
      d.row.original?.metadata || {};
    const handleTime = determineHandleTimeForServiceRequest({
      createdAt,
      dispatchedInternallyAt,
      dispatchedToEquipsAt,
      dispatchedToProviderAt,
    });

    const result = getHandleTimeLabel({ handleTime, requestStatus });

    return result.toString();
  },
};

export const notes = {
  id: 'notes',
  Header: <Translation>{(t) => <>{t('notes')}</>}</Translation>,
  accessor: (data) => data.notes,
  width: defaultColumnWidths.largeText,
  disableSortBy: true,
  toText: (d) => {
    return standardNoteSerializerAndDeserializer.serializer(d.row.original?.notes || []);
  },
  Cell: (d) => (
    <TableCellNotes
      entityId={d.row.original?.metadata?.serviceRequestId || ''}
      parentLabel={getEnumValue(allowedNoteParentLabels.serviceRequest)}
      noteQueryFunction={getServiceRequestNoteList}
      noteText={d.row.original?.notes?.[0]?.metadata?.message}
    />
  ),
};

export const closingNote = {
  id: 'closingNote',
  Header: <Translation>{(t) => <>{t('closingNotes')}</>}</Translation>,
  accessor: (data) => data.notes,
  width: defaultColumnWidths.largeText,
  disableSortBy: true,
  toText: (data) => data.value?.filter((n) => n?.metadata?.tags?.includes(NotesTagsEnum.ClosingNotes))?.[0]?.metadata?.message,
  Cell: (data) => (
    <TableCellExpand>
      {data.value?.filter((n) => n?.metadata?.tags?.includes(NotesTagsEnum.ClosingNotes))?.[0]?.metadata?.message}
    </TableCellExpand>
  ),
};

export const repairDescription = {
  id: 'repairDescription',
  Header: <Translation>{(t) => <>{t('repairDescription')}</>}</Translation>,
  accessor: (data) => data.incidents?.[0]?.charges?.[0]?.metadata?.invoice?.metadata?.repairDescription,
  disableSortBy: true,
  width: defaultColumnWidths.largeText,
  toText: (data) => data.value || '',
  Cell: (data) => <TableCellExpand>{data.value}</TableCellExpand>,
};

export const sublocationIds = {
  id: 'sublocationIds',
};

export const approvals = {
  id: 'approvals',
  Header: <Translation>{(t) => <>{t('approvals')}</>}</Translation>,
  filterOptions: {
    hidden: false,
    type: TableFilterTypes.select,
    options: [
      { label: 'Any', value: '' },
      { label: 'Open', value: 'open' },
      { label: 'Completed', value: 'completed' },
      { label: 'Approved', value: 'approved' },
      { label: 'Denied', value: 'denied' },
    ],
  },
};

export const noteAttachment = {
  id: 'note.attachment',
  filterOptions: {
    hidden: false,
    label: <Translation>{(t) => <>{t('hasANoteWithAttachment')}</>}</Translation>,
    type: TableFilterTypes.select,
    options: [
      { label: 'Any', value: '' },
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' },
    ],
  },
};

export const serviceRequestId = {
  id: 'serviceRequestId',
  Header: <Translation>{(t) => <>{t('serviceRequestId')}</>}</Translation>,
  accessor: (data) => data.metadata?.serviceRequestId,
  disableSortBy: true,
  width: defaultColumnWidths.largeText,
  toText: (data) => data.value || '',
  Cell: (data) => <TableCellCopyData data={data.value}>{data.value}</TableCellCopyData>,
  filterOptions: {
    type: TableFilterTypes.text,
  },
};

export const deactivatedEquipment = {
  id: 'equipmentMetadata.deactivated',
  filterOptions: {
    hidden: false,
    label: <Translation>{(t) => <>{t('equipmentIsDeactivated')}</>}</Translation>,
    type: TableFilterTypes.select,
    options: [
      { label: 'Any', value: '' },
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' },
    ],
  },
};

export const noteFromExternalUser = {
  id: 'note.fromExternalUser',
  filterOptions: {
    hidden: false,
    label: <Translation>{(t) => <>{t('noteFromExternalUser')}</>}</Translation>,
    type: TableFilterTypes.select,
    options: [
      { label: 'Any', value: '' },
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' },
    ],
  },
};

export const metadataIgnoreUntil = {
  id: 'metadata.ignoreUntil',
  Header: <Translation>{(t) => <>{t('ignoreUntil')}</>}</Translation>,
  accessor: (data) => data.metadata?.ignoreUntil,
  width: defaultColumnWidths.smallText,
  toText: (data) => {
    const time = toDateStringFromUnixMillisecondTimestamp(data?.value, {
      customFormatString: 'M/D/YY h:mma',
    });
    return time;
  },
  Cell: (data) => {
    return <DisplayTime timestamp={data?.value} />;
  },
  filterOptions: {
    type: TableFilterTypes.dateRange,
  },
};

export const metadataCancelationRequested = {
  id: 'metadata.cancelationRequested',
  Header: <Translation>{(t) => <>{t('cancelationRequested')}</>}</Translation>,
  accessor: (data) => data.metadata?.cancelationRequested,
  disableSortBy: true,
  filterOptions: {
    type: TableFilterTypes.select,
    options: [
      { label: 'Any', value: '' },
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' },
    ],
  },
  width: defaultColumnWidths.normalText,
  Cell: (data) => (data.value ? data.t('yes') : data.t('no')),
};

import React, { useEffect, useMemo, useState } from 'react';
import {
  Address,
  Maybe,
  Organization,
  OrganizationMetadata,
  OrganizationTagsEnum,
  ProviderOrganizationData,
  ProviderSourceEnum,
  queryOrganizations,
} from '@equips/entities-schema';
import AsyncSelect from 'react-select/async';
import { useQuery } from '@tanstack/react-query';
import debouncePromise from 'awesome-debounce-promise';
import { formatAddressAsOneLine } from '@equips/common-resources';
import Email from '@mui/icons-material/Email';
import Hardware from '@mui/icons-material/Hardware';
import Language from '@mui/icons-material/Language';
import LocationOn from '@mui/icons-material/LocationOn';
import Phone from '@mui/icons-material/Phone';
import Recommend from '@mui/icons-material/Recommend';
import Reviews from '@mui/icons-material/Reviews';
import Star from '@mui/icons-material/Star';
import StickyNote2 from '@mui/icons-material/StickyNote2';
import Work from '@mui/icons-material/Work';
import Badge from '@mui/material/Badge';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  clientValueForFilter,
  getOrganizationsForSelect,
  providerValueForFilter,
} from '../../../graphql/queries/organizationGraphQLQueries';
import GraphqlErrors from '../../../graphql/GraphqlErrors';
import createRandomString from '../../functions/createRandomString';
import FormGroup from '../Form/FormGroup';
import Label from '../Form/Label';
import InputLoader from '../Form/InputLoader';
import Input from '../Form/Input';
import InputHelper from '../Form/InputHelper';
import { NOT_NULL_OPTION_VALUE, NULL_OPTION_VALUE } from '../../../graphql/enums';
import Thumbtack from '../Icons/Thumbtack';
import Urls from '../../../routes/Urls';
import { defaultSelectStyles } from './defaultSelectStyles';
import ReactSelectRequiredInput from './ReactSelectRequiredInput';

export type OrganizationSelectOptionType = {
  value: string;
  label: string;
  address: Address;
  provider: ProviderOrganizationData & { distance?: string };
  billingAddress?: Address;
  metadata?: OrganizationMetadata;
};

export const formatOrganizationForReactSelect = (organization: Maybe<Organization>): OrganizationSelectOptionType => {
  if (organization?.metadata?.organizationId && organization?.metadata?.organizationName) {
    return {
      value: organization?.metadata?.organizationId,
      label: organization?.metadata?.organizationName,
      address: organization?.billingAddress ?? {},
      provider: organization?.provider ?? {},
      metadata: organization?.metadata,
    };
  }

  return { value: '', label: '', address: {}, provider: {} };
};

export const OrganizationSelectOption = ({
  innerProps = {},
  innerRef = undefined,
  isDisabled = false,
  data,
  isSelected = false,
  hoverStyle = true,
}) => {
  const { t } = useTranslation();
  const organizationName = data?.label ?? data?.metadata?.organizationName;
  const address = formatAddressAsOneLine(data?.address ?? data?.billingAddress);
  const isExternalProvider = data?.provider?.isExternalProvider;
  const email = data?.provider?.dispatchEmail;
  const phone = data?.provider?.dispatchPhone;
  const website = data?.provider?.dispatchWebsite;
  const distance = data?.provider?.distance;

  const providerSource = data?.provider?.providerSource as ProviderSourceEnum | undefined;
  const completedJobCount = data?.provider?.completedServiceRequestCount;
  const completedJobCountDisplay = completedJobCount > 9 ? '9+' : completedJobCount;
  const noteCount = data?.provider?.noteCount > 25 ? '25+' : data?.provider?.noteCount;

  const { pathname } = useLocation();
  const pathParts = pathname.split('/');
  const showExtraIconsAasExternalClaimForm = Urls.AAS_ARMIS_SERVICE_REQUEST_FORM !== pathParts.join('/');
  const tradeMatch = !!data?.provider?.tradeMatch;

  if (isDisabled) return null;

  return (
    <div
      ref={innerRef}
      {...innerProps}
      className={`px-4 py-2 text-left ${
        isSelected ? 'bg-blue-600 text-white' : `border-b border-t border-gray-200 text-gray-700 ${hoverStyle ? ' hover:bg-blue-100' : ''}`
      }`}
    >
      <div className={`text-lg ${isSelected ? 'text-white' : 'text-gray-800'} flex justify-between gap-2`}>
        {organizationName}
        {isExternalProvider && showExtraIconsAasExternalClaimForm && (
          <div className="flex gap-4 text-2xl">
            {tradeMatch && (
              <div className="text-blue-500" title={`This provider services the trade of the selected failure category.`}>
                <Badge badgeContent={<Star className="text-yellow-500" fontSize="inherit" />}>
                  <Hardware fontSize="inherit" />
                </Badge>
              </div>
            )}
            {(noteCount || noteCount === 0) && (
              <div className="text-yellow-500" title={`${noteCount} ${t('notes')}`}>
                <Badge color="primary" badgeContent={noteCount} showZero>
                  <StickyNote2 fontSize="inherit" />
                </Badge>
              </div>
            )}
            {(completedJobCount || completedJobCount === 0) && (
              <div className="text-gray-700" title={`${completedJobCountDisplay} completed ${t('requests')}`}>
                <Badge color="primary" badgeContent={completedJobCountDisplay} showZero>
                  <Work fontSize="inherit" />
                </Badge>
              </div>
            )}
            {providerSource && (
              <div className="flex items-center gap-1">
                {providerSource === ProviderSourceEnum.PreferredProviderList && (
                  <div className="text-green-500" title="Preferred Provider List">
                    <Recommend fontSize="inherit" />
                  </div>
                )}
                {providerSource === ProviderSourceEnum.Referral && (
                  <div className="text-green-500" title="Referral">
                    <Reviews fontSize="inherit" />
                  </div>
                )}
                {providerSource === ProviderSourceEnum.Thumbtack && (
                  <div className="text-blue-500" title="Thumbtack">
                    <Thumbtack fontSize="inherit" />
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
      {address && (
        <div className="flex items-center gap-1 text-sm">
          <LocationOn fontSize="inherit" />
          {address}
          {distance && (
            <p>
              {' · '}
              <span className="text-equipsGreen">{distance} mi</span>
            </p>
          )}
        </div>
      )}
      {isExternalProvider && (
        <div className="space-y-1 text-sm ">
          {email && (
            <div className="flex items-center gap-1">
              <Email fontSize="inherit" /> {email}
            </div>
          )}
          {phone && (
            <div className="flex items-center gap-1">
              <Phone fontSize="inherit" /> {phone}
            </div>
          )}
          {website && (
            <div className="flex items-center gap-1">
              <Language fontSize="inherit" /> {website}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export type OrganizationSelectTypes = {
  organizationId?: string;
  organizationTags?: Maybe<OrganizationTagsEnum>[];
  readOnly?: boolean;
  changeOrganization?: any;
  fullWidth?: boolean;
  isClearable?: boolean;
  label?: string | React.ReactNode;
  loading?: boolean;
  required?: boolean;
  typeOfOrganizationToFind?: 'ANY' | typeof clientValueForFilter | typeof providerValueForFilter | string;
  testId?: string;
  helper?: string;
  labelHelper?: string;
  placeholder?: string;
  options?: OrganizationSelectOptionType[] | { label: string; options: OrganizationSelectOptionType[] }[];
  showDetails?: boolean;
  allowNullNotNull?: boolean;
  id: string;
  setInitialOrganization?: boolean;
  disabled?: boolean;
};

export default function OrganizationsSelect({
  organizationId,
  organizationTags = [],
  readOnly = false,
  changeOrganization,
  fullWidth = false,
  isClearable = false,
  label,
  loading = false,
  required = false,
  typeOfOrganizationToFind = 'ANY',
  testId = '',
  helper = '',
  labelHelper = '',
  placeholder,
  options,
  showDetails = false,
  allowNullNotNull = false,
  id,
  setInitialOrganization = true,
  disabled = false,
}: OrganizationSelectTypes) {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<OrganizationSelectOptionType>();

  const nullOption = useMemo(
    () => ({ value: NULL_OPTION_VALUE, label: `No ${label || t('organization')}`, address: {}, provider: {} }),
    [label, t],
  );
  const notNullOption = useMemo(
    () => ({ value: NOT_NULL_OPTION_VALUE, label: `Has ${label || t('organization')}`, address: {}, provider: {} }),
    [label, t],
  );

  useEffect(() => {
    if (organizationId === nullOption.value) {
      setSelected(nullOption);
    } else if (organizationId === notNullOption.value) {
      setSelected(notNullOption);
    }
  }, [organizationId, nullOption, notNullOption]);

  const { data: defaultOptions } = useQuery(
    ['getOrganizationsForSelect', typeOfOrganizationToFind],
    () => getOrganizationsForSelect({ search: '', typeOfOrganizationToFind, organizationTags }),
    {
      select: (data) => {
        const options = data?.data?.organizations?.data || [];
        return options.map((item) => formatOrganizationForReactSelect(item));
      },
      enabled: !options,
    },
  );

  useQuery([`getSelectedOrganization-${id}-${organizationId}`], () => queryOrganizations({ organizationId }), {
    enabled: !!organizationId && organizationId !== nullOption.value && organizationId !== notNullOption.value,
    onSuccess: (d) => {
      const organization = {
        value: d?.data?.organizations?.data?.[0]?.metadata?.organizationId ?? '',
        label: d?.data?.organizations?.data?.[0]?.metadata?.organizationName ?? '',
        address: d?.data?.organizations?.data?.[0]?.billingAddress ?? {},
        provider: d?.data?.organizations?.data?.[0]?.provider ?? {},
      };
      setInitialOrganization && setSelected(organization);
    },
  });

  const organizationIdSelect = id || `OrganizationIdSelect${createRandomString(5)}`;

  const concurrentCaller = async (search) => {
    try {
      const { data, errors } = await getOrganizationsForSelect({ search, typeOfOrganizationToFind, organizationTags });

      if (errors) {
        GraphqlErrors(errors);
      }
      const organizations = data?.organizations?.data || [];

      return organizations.map((item) => formatOrganizationForReactSelect(item)).filter((o) => o);
    } catch (error) {
      console.error(error);
    }

    return [];
  };

  if (loading) return <InputLoader />;

  return (
    <FormGroup fullWidth={fullWidth} data-testid={testId}>
      <Label id={organizationIdSelect} label={label || t('organization')} required={required} helper={labelHelper || ''} />
      <div className="relative">
        {readOnly ? (
          <Input id="organizationSelectReadOnly" value={selected?.label || ''} readOnly />
        ) : (
          <AsyncSelect
            components={{
              Option: OrganizationSelectOption,
              ...(showDetails && { SingleValue: OrganizationSelectOption }),
            }}
            isClearable={isClearable}
            defaultOptions={options ?? (allowNullNotNull ? [notNullOption, nullOption, ...(defaultOptions || [])] : defaultOptions)}
            value={selected}
            inputId={organizationIdSelect}
            noOptionsMessage={() => null}
            classNamePrefix="equips"
            placeholder={placeholder || t('search')}
            loadOptions={debouncePromise(concurrentCaller, 500)}
            onChange={(organization: OrganizationSelectOptionType) => {
              setSelected(organization);
              changeOrganization(organization);
            }}
            styles={{
              ...defaultSelectStyles,
            }}
            menuPortalTarget={document.body}
            isDisabled={disabled}
          />
        )}
        <input readOnly className="hidden" hidden tabIndex={-1} value={organizationId} data-testid={`${testId}InputValue`} />
        {helper && <InputHelper>{helper}</InputHelper>}
        {required && <ReactSelectRequiredInput isValid={organizationId} />}
      </div>
    </FormGroup>
  );
}

import { lazy } from 'react';

export const AAS_TASKS_URLS = {
  ROOT: '/aas/tasks',
  AAS_TASKS_ITEM_DETAILS: '/details/:entity/:id',
};

export const aasTasksRoutes = [
  {
    path: AAS_TASKS_URLS.ROOT,
    component: lazy(() => import('../AasTasksApp')),
    routes: [
      {
        path: `${AAS_TASKS_URLS.ROOT}${AAS_TASKS_URLS.AAS_TASKS_ITEM_DETAILS}`,
        component: lazy(() => import('../screens/view-aas-task/ViewAasTask')),
      },
    ],
  },
];

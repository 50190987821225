import React from 'react';
import Skeleton from './Skeleton';

interface LoaderCardsProps {
  parentClassName?: string;
  childClassName?: string;
  parentQuantity?: number;
  childQuantity?: number;
}

export default function LoaderCards({
  parentQuantity = 3,
  parentClassName = 'mb-16',
  childQuantity = 4,
  childClassName = 'bg-white p-2 w-full mb-4 rounded shadow',
}: LoaderCardsProps) {
  return (
    <div className={parentClassName}>
      {[...Array(parentQuantity)].map((_, i) => (
        <div key={i} className={childClassName}>
          {[...Array(childQuantity)].map((_, j) => (
            <div key={j} className="leading-tightest mb-1 text-2xl">
              <Skeleton />
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

import { gql } from '../../../graphql/graphqlHelperFunctions';

export const NoteListFields = `
  metadata {
    authorizationLevel
    parentId
    parentLabel
    message
    aiGeneratedSummary
    tags
    createdAt
    createdByUser {
      metadata {
        fullName
        userId
        profilePictureUrl
        organizationId
        organizationName
        clientTags {
          name
          style
        }
        defaultTagId
      }
    }
    modifiedAt
    modifiedByUser {
      metadata {
        fullName
        userId
        profilePictureUrl
        organizationId
        organizationName
      }
    }
    attachments {
      metadata {
      attachmentId
        location
        name
        contentType
        presignedGetUrl
        location
      }
    }
    noteId
    parentLabel
    serviceRequestId
    organizationId
  }
`;

export const NoteListFragment = {
  fragments: {
    noteComponentData: gql`
      fragment NoteComponentData on Note {
        metadata {
          authorizationLevel
          aiGeneratedSummary
          message
          tags
          createdAt
          createdByUser {
            metadata {
              fullName
              userId
              profilePictureUrl
              organizationName
              organizationId
              clientTags {
                name
                style
              }
              defaultTagId
            }
          }
          reactions {
            metadata {
              noteId
              message
              createdByUser {
                metadata {
                  fullName
                  userId
                }
              }
            }
          }
          modifiedAt
          modifiedByUser {
            metadata {
              fullName
              userId
              profilePictureUrl
            }
          }
          attachments {
            metadata {
              attachmentId
              name
              contentType
              presignedGetUrl
              location
              createdAt
            }
          }
          noteId
          parentLabel
          serviceRequestId
          organizationId
        }
      }
    `,
  },
};

import React from 'react';
import { formatAddress } from '@equips/common-resources';
import { Address } from '@equips/entities-schema';

interface AddressColumnProps {
  addressObject?: Address | null;
}

export default function AddressColumn({ addressObject = {} }: AddressColumnProps) {
  const [partOne] = formatAddress(addressObject);

  return <>{partOne}</>;
}

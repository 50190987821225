import React, { Fragment, useState, lazy, Suspense } from 'react';
import ReactSelect from 'react-select';
import CreatableSelect from 'react-select/creatable';
import Button from '../Buttons/Button';
import UnderlinedButton from '../Buttons/UnderlinedButton';
import slugify from '../../functions/slugify';
import TimeZoneSelector from '../Selects/TimeZoneSelector';
import { defaultSelectStyles } from '../Selects/defaultSelectStyles';
import StyledSelect from '../Selects/StyledSelect';
import ReactSelectRequiredInput from '../Selects/ReactSelectRequiredInput';
import Label from './Label';
import FormGroup from './FormGroup';
import DuplicateEntityChecker from './DuplicateEntityChecker';
import FormFieldErrorMessage from './FormFieldErrorMessage';
import Input from './Input';

const RadioCard = lazy(() => import('./RadioCards'));
const CurrencyInput = lazy(() => import('./CurrencyInput'));
const PhoneNumber = lazy(() => import('./PhoneNumber'));
const StateSelector = lazy(() => import('../Selects/StateSelector'));
const CountrySelector = lazy(() => import('../Selects/CountrySelector'));
const DateInput = lazy(() => import('../Date/DateInput'));
const Switch = lazy(() => import('../Switch/Switch'));
const Checkbox = lazy(() => import('./Checkbox'));
const Textarea = lazy(() => import('./Textarea'));
const SingleValueTypeAhead = lazy(() => import('../Selects/SingleValueTypeAhead'));
const AttachmentDropzone = lazy(() => import('../Files/AttachmentDropzone'));
const ColorPicker = lazy(() => import('../Color/ColorPicker'));
const KeyValueArrayInput = lazy(() => import('./KeyValueArrayInput'));

export const fieldTypes = {
  number: 'number',
  text: 'text',
  ERROR_MESSAGE: 'ERROR_MESSAGE',
  DATE: 'DATE',
  SWITCH: 'SWITCH',
  CHECKBOX: 'CHECKBOX',
  TEXTAREA: 'TEXTAREA',
  STATE_SELECT: 'STATE_SELECT',
  COUNTRY_SELECT: 'COUNTRY_SELECT',
  TIMEZONE_SELECT: 'TIMEZONE_SELECT',
  PHONE: 'PHONE',
  MONEY: 'MONEY',
  TYPE_AHEAD: 'TYPE_AHEAD',
  RADIO_CARDS: 'RADIO_CARDS',
  SELECT: 'SELECT',
  SIMPLE_SELECT: 'SIMPLE_SELECT',
  CREATABLE_SELECT: 'CREATABLE_SELECT',
  ATTACHMENT_DROPZONE: 'ATTACHMENT_DROPZONE',
  HYPERLINK: 'HYPERLINK',
  COLOR: 'COLOR',
  KEY_VALUE_ARRAY: 'KEY_VALUE_ARRAY',
};

enum FieldTypesEnum {
  number = 'number',
  text = 'text',
  DATE = 'DATE',
  SWITCH = 'SWITCH',
  CHECKBOX = 'CHECKBOX',
  TEXTAREA = 'TEXTAREA',
  STATE_SELECT = 'STATE_SELECT',
  PHONE = 'PHONE',
  MONEY = 'MONEY',
  TYPE_AHEAD = 'TYPE_AHEAD',
  RADIO_CARDS = 'RADIO_CARDS',
  SELECT = 'SELECT',
  KEY_VALUE_ARRAY = 'KEY_VALUE_ARRAY',
  ERROR_MESSAGE = 'ERROR_MESSAGE',
}

function FieldInputAreaWithLoader({
  loading = false,
  handleChange,
  placeholder = '',
  label,
  id,
  value = '',
  type = FieldTypesEnum.text,
  required = false,
  helper = '',
  inputHelper = '',
  typeAheadOptions = [],
  options = [],
  mask,
  radix,
  isMulti = false,
  className,
  ...restOfProps
}: FieldInputAreaWithLoaderAttributes) {
  if (type === fieldTypes.DATE) {
    return (
      <Suspense fallback={<div />}>
        {!loading && <Label id={id} label={label} required={required} helper={helper} />}
        <DateInput
          data-testid={id}
          loading={loading}
          value={value || ''}
          id={id}
          required={required}
          onChange={handleChange}
          helper={inputHelper}
          {...restOfProps}
        />
      </Suspense>
    );
  } else if (type === fieldTypes.MONEY) {
    return (
      <CurrencyInput
        discardFormGroup
        labelHelper={helper}
        fullWidth
        label={label}
        data-testid={id}
        loading={loading}
        cents={value || ''}
        id={id}
        required={required}
        handleCents={(value) => handleChange({ target: { value, id } })}
        {...restOfProps}
      />
    );
  } else if (type === fieldTypes.PHONE) {
    return (
      <PhoneNumber
        discardFormGroup
        fullWidth
        label={label}
        data-testid={id}
        loading={loading}
        value={value || ''}
        inputId={id}
        required={required}
        onChange={(value) => handleChange({ target: { value, id } })}
        placeholder={placeholder}
        {...restOfProps}
      />
    );
  } else if (type === fieldTypes.RADIO_CARDS) {
    return (
      <RadioCard
        labelHelper={helper}
        id={id}
        loading={loading}
        label={label}
        onChange={(value) => handleChange({ target: { value, id } })}
        value={value}
        options={options}
        {...restOfProps}
      />
    );
  } else if (type === fieldTypes.SWITCH) {
    return (
      <Suspense fallback={<div />}>
        <Switch label={label} loading={loading} checked={value} onChange={handleChange} id={id} {...restOfProps} />
      </Suspense>
    );
  } else if (type === fieldTypes.STATE_SELECT) {
    return (
      <StateSelector
        discardFormGroup
        fullWidth
        loading={loading}
        label={label}
        id={id}
        required={required}
        name="stateUnabbreviated"
        value={value || ''}
        onChange={handleChange}
        {...restOfProps}
      />
    );
  } else if (type === fieldTypes.COUNTRY_SELECT) {
    return (
      <CountrySelector
        discardFormGroup
        fullWidth
        loading={loading}
        label={label}
        id={id}
        name="countryUnabbreviated"
        value={value || ''}
        onChange={handleChange}
        {...restOfProps}
      />
    );
  } else if (type === fieldTypes.TIMEZONE_SELECT) {
    return (
      <TimeZoneSelector
        discardFormGroup
        fullWidth
        loading={loading}
        label={label}
        id={id}
        value={value || ''}
        onChange={handleChange}
        {...restOfProps}
      />
    );
  } else if (type === fieldTypes.SELECT) {
    return (
      <div className="relative">
        <Label label={label} id={id} required={required} helper={helper} />
        <ReactSelect
          {...(value && { value })}
          aria-label={label}
          styles={defaultSelectStyles}
          className="block bg-gray-100"
          isLoading={loading}
          id={id}
          onChange={(option) =>
            handleChange({
              target: {
                id,
                value: !isMulti ? option?.value : option || [],
                option,
              },
            })
          }
          options={options}
          defaultValue={value}
          placeholder={placeholder}
          isMulti={isMulti}
          {...restOfProps}
        />
        {required && <ReactSelectRequiredInput isValid={!!value?.length} />}
      </div>
    );
  } else if (type === fieldTypes.CREATABLE_SELECT) {
    return (
      <>
        <Label label={label} id={id} required={required} helper={helper} />
        <CreatableSelect
          aria-label={label}
          styles={defaultSelectStyles}
          className="block bg-gray-100"
          isLoading={loading}
          required={required}
          id={id}
          onChange={(option) => handleChange({ target: { id, value: option?.value, option } })}
          options={options}
          value={value}
          placeholder={placeholder}
          {...restOfProps}
        />
      </>
    );
  } else if (type === fieldTypes.SIMPLE_SELECT) {
    return (
      <>
        <Label className="pb-1" label={label} id={id} required={required} />
        <StyledSelect
          id={id}
          value={value[0]?.value}
          loading={loading}
          onChange={(option) => handleChange({ target: { id, value: option?.target?.value, option } })}
          required={required}
          isLight={true}
        >
          {!value[0]?.value && <option value="">{placeholder}</option>}
          {options.map((option) => (
            <option key={option?.value} value={option?.value}>
              {option?.label}
            </option>
          ))}
        </StyledSelect>
      </>
    );
  } else if (type === fieldTypes.TEXTAREA) {
    return (
      <Suspense fallback={<div />}>
        <Label id={id} label={label} required={required} />
        <Textarea
          data-testid={id}
          loading={loading}
          value={value || ''}
          placeholder={placeholder || label}
          id={id}
          required={required}
          onChange={handleChange}
          {...restOfProps}
        />
      </Suspense>
    );
  } else if (type === fieldTypes.CHECKBOX) {
    return (
      <Suspense fallback={<div />}>
        <Checkbox
          helper={helper}
          label={label}
          required={required}
          checkboxesAriaLabel={label}
          checked={!!value}
          onChange={handleChange}
          id={id}
          inputTestId={id}
          {...restOfProps}
        />
      </Suspense>
    );
  } else if (type === fieldTypes.TYPE_AHEAD) {
    return (
      <Suspense fallback={<div />}>
        <SingleValueTypeAhead
          discardFormGroup
          isClearable
          fullWidth
          inputId={id}
          options={typeAheadOptions}
          handleChange={handleChange}
          label={label}
          placeholder={placeholder || label}
          loading={loading}
          required={required}
          selectedValue={value}
          {...restOfProps}
        />
      </Suspense>
    );
  } else if (type === fieldTypes.ERROR_MESSAGE) {
    if (!value) return <></>;
    return (
      <Suspense fallback={<div />}>
        <FormFieldErrorMessage className={className} type="error" rule={value} />
      </Suspense>
    );
  } else if (type === fieldTypes.ATTACHMENT_DROPZONE) {
    return (
      <Suspense fallback={<div />}>
        <AttachmentDropzone
          data-testid={id}
          label={label}
          onChange={handleChange}
          maxAttachments={1}
          acceptedFileTypes={['image/*', 'application/pdf']}
          errorMessage="The file could not be uploaded. Attachments must be images or PDFs under 5MB."
          {...restOfProps}
        />
      </Suspense>
    );
  } else if (type === fieldTypes.HYPERLINK) {
    return (
      <a className="text-sm underline" href={value.URL} target="_blank" rel="noreferrer">
        {value.text}
      </a>
    );
  } else if (type === fieldTypes.COLOR) {
    return (
      <Suspense fallback={<div />}>
        <ColorPicker
          discardFormGroup
          fullWidth
          label={label}
          data-testid={id}
          loading={loading}
          value={value || undefined}
          inputId={id}
          required={required}
          onChange={(value) => handleChange({ target: { value, id } })}
          {...restOfProps}
        />
      </Suspense>
    );
  } else if (type === fieldTypes.KEY_VALUE_ARRAY) {
    return (
      <Suspense fallback={<div />}>
        <KeyValueArrayInput
          label={label}
          loading={loading}
          value={value || undefined}
          inputId={id}
          required={required}
          onChange={(value) => handleChange({ target: { value, id } })}
          {...restOfProps}
        />
      </Suspense>
    );
  }

  return (
    <>
      <Label id={id} label={label} required={required} helper={helper} />
      <Input
        loading={loading}
        name={id}
        value={value || ''}
        placeholder={placeholder || label}
        id={id}
        required={required}
        onChange={handleChange}
        data-testid={id}
        helper={inputHelper}
        type={type}
        mask={mask}
        radix={radix}
        {...restOfProps}
      />
    </>
  );
}

type FieldInputAreaWithLoaderAttributes = {
  loading?: boolean;
  helper?: any;
  inputHelper?: any;
  required?: boolean;
  handleChange: any;
  placeholder?: string;
  typeAheadOptions?: any;
  label: string;
  id: string;
  value?: any;
  options?: any;
  type?: FieldTypesEnum;
  mask?: string;
  radix?: string;
  isMulti?: boolean;
  className?: string;
};

type GetFieldsAttributes = {
  heading?: string;
  subheading?: string;
  fields: Array<any>;
  isLastItem?: boolean;
};

type field = {
  heading?: string;
  subheading?: string;
  fields?: any;
  hidden?: boolean;
};

type FormFieldAttributes = {
  loading?: boolean;
  buttonFullWidth?: boolean;
  userCanSubmitForm?: boolean;
  disableSubmit?: boolean;
  saving?: boolean;
  handleChange?: any;
  fields: Array<field>;
  primaryButton?: string;
  includeHeader?: boolean;
  submitButtonHelperText?: string;
};

function FormFields({
  includeHeader = true,
  fields,
  loading = false,
  saving = false,
  handleChange,
  primaryButton,
  userCanSubmitForm = true,
  disableSubmit = false,
  buttonFullWidth = false,
  submitButtonHelperText,
}: FormFieldAttributes) {
  const [hiddenIsOpen, setHiddenIsOpen] = useState({});

  const GetFields = ({ heading = '', subheading, fields = [], isLastItem }: GetFieldsAttributes) => {
    return (
      <div key={heading || fields?.[0].id} className={`py-4 ${!isLastItem ? 'border-b' : ''} border-gray-300`}>
        {heading && includeHeader && (
          <div className="w-full px-1 pb-2 text-gray-700 ">
            <h4 className="font-semibold md:text-base">{heading}</h4>
            {subheading && <p className="mt-1 text-sm">{subheading}</p>}
          </div>
        )}

        <div className="flex w-full flex-wrap justify-between px-1">
          {fields.map((field) => {
            if (field.hidden) return null;

            if (field.as) {
              return <Fragment key={field.id}>{field.as}</Fragment>;
            }

            const {
              placeholder,
              label,
              id,
              value,
              valid,
              rule,
              warning,
              required,
              isHalf,
              helper,
              inputHelper,
              duplicateCheckerFunction,
              formGroupTestId,
              ...restOfProps
            } = field;

            return (
              <FormGroup key={id} fullWidth={!isHalf} data-testid={formGroupTestId}>
                <FieldInputAreaWithLoader
                  helper={helper}
                  inputHelper={inputHelper}
                  required={required}
                  loading={loading}
                  handleChange={handleChange}
                  placeholder={placeholder}
                  label={label}
                  id={id}
                  value={value}
                  mask={field?.mask}
                  radix={field?.radix}
                  {...restOfProps}
                />
                {valid === false && <FormFieldErrorMessage rule={rule} />}
                {warning && <FormFieldErrorMessage rule={warning} type="warning" />}
                {duplicateCheckerFunction && <DuplicateEntityChecker value={value} duplicateCheckerFunction={duplicateCheckerFunction} />}
              </FormGroup>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div className="mb-4">
      {fields.map((fieldSet, i) => {
        const { hidden, heading, subheading, fields: fieldSetFields } = fieldSet;
        const headingAsSlug = slugify(heading);
        const isLastItem = i === fields.length - 1;

        if (hidden) {
          return (
            <div className="mt-2" key={heading}>
              <div>
                <UnderlinedButton
                  data-testid="advancedButton"
                  tiny
                  type="button"
                  className="mb-6"
                  onClick={() =>
                    setHiddenIsOpen((state) => ({
                      ...state,
                      [headingAsSlug]: state[headingAsSlug] ? false : true,
                    }))
                  }
                >
                  {`${hiddenIsOpen[headingAsSlug] ? 'Hide' : 'Show'} ${heading}`}
                </UnderlinedButton>
              </div>

              {hiddenIsOpen[headingAsSlug] && (
                <div key={headingAsSlug} data-testid="advancedHolder" className="mt-0">
                  {GetFields({ heading, subheading, fields: fieldSetFields, isLastItem })}
                </div>
              )}
            </div>
          );
        }

        return GetFields({ heading, subheading, fields: fieldSetFields, isLastItem });
      })}
      {primaryButton && userCanSubmitForm && (
        <div className="flex flex-row justify-end py-2 md:py-6">
          {submitButtonHelperText && <p className="mr-6 text-xs font-base text-gray-600">{submitButtonHelperText}</p>}
          <Button
            disabled={disableSubmit}
            fullWidth={buttonFullWidth}
            loading={loading || saving}
            data-testid="formfieldsSubmitButton"
            type="submit"
          >
            {primaryButton}
          </Button>
        </div>
      )}
    </div>
  );
}

export default FormFields;

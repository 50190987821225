import React from 'react';
import Label from './Label';
import './radioStyles.css';

interface RadioProps {
  id: string;
  label: string;
  required?: boolean;
  isHalf?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  value: string;
  labelClassName?: string;
  options: { label: string; value: string }[];
}

export default function Radio({
  onChange,
  options,
  value: radioGroupValue,
  required = false,
  label,
  labelClassName = '',
  isHalf = false,
  id,
}: RadioProps) {
  return (
    <fieldset>
      <legend className={labelClassName}>{label}</legend>
      <div className="flex flex-wrap">
        {options.map(({ value, label }) => (
          <Label
            className={`${isHalf ? 'w-1/2' : 'w-full'} py-1`}
            key={value}
            id={value}
            label={
              <>
                <input
                  required={required}
                  type="radio"
                  className="radio-input"
                  onChange={onChange}
                  id={value}
                  name={id}
                  value={value}
                  checked={radioGroupValue === value}
                />
                <span className={`ml-2`}>{label}</span>
              </>
            }
          />
        ))}
      </div>
    </fieldset>
  );
}

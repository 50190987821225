import { API, Auth } from 'aws-amplify';
import config from '../../config';

const configureAmplify = () => {
  API.configure({ endpoints: config.apiGateways });
  Auth.configure(config.cognito);
  // needed for invoke-entities
  window.AmplifyAPI = API;
};

export default configureAmplify;

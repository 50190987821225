import React, { useState } from 'react';
import {
  postOrganization,
  OrganizationTagsEnum,
  patchOrganization,
  PaymentPreferenceType,
  Maybe,
  ProviderSourceEnum,
  AasFailGroupEnumType,
} from '@equips/entities-schema';
import { useQuery } from '@tanstack/react-query';
import FormFields, { fieldTypes } from '../../../common/components/Form/FormFields';
import validateEmail from '../../../common/functions/validateEmail';
import { getDomainFromEmailAddress } from '../../../common/functions/isPublicEmail';
import { useAlert, AlertTypes } from '../../../common/components/Alerts/AlertContext';
import { getOrganizationById } from '../../../graphql/queries/organizationGraphQLQueries';
import { useDuplicateProviderChecker } from '../../../common/hooks/useDuplicateProviderChecker';
import { enumObjectToSelectOptions } from '../../../graphql/enums';
import { AasFailGroupToTextMap } from '../components/AasFailCodesSelect';

export const paymentPreferenceEnum = {
  [PaymentPreferenceType.Check]: { value: PaymentPreferenceType.Check, name: 'Check' },
  [PaymentPreferenceType.CreditCard]: { value: PaymentPreferenceType.CreditCard, name: 'Credit Card' },
};

export const providerSourceEnum = {
  [ProviderSourceEnum.PreferredProviderList]: { value: ProviderSourceEnum.PreferredProviderList, name: 'Preferred Provider List' },
  [ProviderSourceEnum.Referral]: { value: ProviderSourceEnum.Referral, name: 'Referral' },
  [ProviderSourceEnum.Tarp]: { value: ProviderSourceEnum.Tarp, name: 'The Appliance Repair Professionals' },
  [ProviderSourceEnum.Thumbtack]: { value: ProviderSourceEnum.Thumbtack, name: 'Thumbtack' },
  [ProviderSourceEnum.ServicePower]: { value: ProviderSourceEnum.ServicePower, name: 'ServicePower' },
};

const defaultState = {
  providerName: '',
  providerPhone: '',
  providerEmail: '',
  providerStreetAddress: '',
  providerCity: '',
  providerState: '',
  providerZip: '',
  paymentPreference: null,
  providerSource: null,
  typeOfWork: [],
};
export const paymentPreferenceOptions = enumObjectToSelectOptions(paymentPreferenceEnum);
export const providerSourceOptions = enumObjectToSelectOptions(providerSourceEnum);
const failCodeOptions = Object.keys(AasFailGroupToTextMap).map((value) => ({ label: AasFailGroupToTextMap[value], value }));

const AasCreateEditProvider = (props: { organizationId?: string; onSuccess?: (...args: any[]) => any }) => {
  const { organizationId } = props;
  const isEditing = !!organizationId;

  const [
    {
      providerName,
      providerPhone,
      providerEmail,
      providerStreetAddress,
      providerCity,
      providerState,
      providerZip,
      paymentPreference,
      providerSource,
      typeOfWork,
    },
    setState,
  ] = useState<
    Omit<typeof defaultState, 'paymentPreference' | 'providerSource' | 'typeOfWork'> & {
      paymentPreference: Maybe<PaymentPreferenceType>;
      providerSource: Maybe<ProviderSourceEnum>;
      typeOfWork: Maybe<AasFailGroupEnumType>[];
    }
  >(defaultState);

  const [savingOrganization, setSavingOrganization] = useState(false);

  const { isFetching } = useQuery(['getOrganizationById', organizationId], () => getOrganizationById({ organizationId }), {
    select: (data) => data?.data?.organizations?.data?.[0],
    enabled: isEditing,
    onSuccess: (organization) => {
      setState({
        providerName: organization?.metadata?.organizationName ?? '',
        providerPhone: organization?.provider?.dispatchPhone ?? '',
        providerEmail: organization?.provider?.dispatchEmail ?? '',
        providerStreetAddress: organization?.billingAddress?.line1 ?? '',
        providerCity: organization?.billingAddress?.city ?? '',
        providerState: organization?.billingAddress?.stateUnabbreviated ?? '',
        providerZip: organization?.billingAddress?.zip ?? '',
        paymentPreference: organization?.provider?.paymentPreference ?? null,
        providerSource: organization?.provider?.providerSource ?? null,
        typeOfWork: organization?.provider?.typeOfWork || [],
      });
    },
  });

  const { isDuplicateProvider, checkAndSetDuplicateProvider } = useDuplicateProviderChecker(isEditing);

  const showAlert = useAlert();

  const saveOrganization = async () => {
    setSavingOrganization(true);
    try {
      const organizationData = {
        metadata: {
          organizationName: providerName,
          urlDomain: providerEmail ? getDomainFromEmailAddress(providerEmail) : '',
          tags: [OrganizationTagsEnum.Aas],
        },
        provider: {
          isExternalProvider: true,
          dispatchEmail: providerEmail,
          dispatchPhone: providerPhone,
          paymentPreference: paymentPreference ? paymentPreference : null,
          providerSource,
          typeOfWork,
        },
        billingAddress: {
          line1: providerStreetAddress,
          city: providerCity,
          stateUnabbreviated: providerState,
          zip: providerZip,
          countryUnabbreviated: 'United States',
        },
      };

      const response = isEditing
        ? await patchOrganization({ organizationId, ...organizationData })
        : await postOrganization(organizationData);

      if (response?.errors?.length) {
        throw new Error(response?.errors?.[0].message);
      }

      showAlert({ content: 'Provider successfully saved' });

      props?.onSuccess?.();
    } catch (error) {
      console.error(error);
      showAlert({ type: AlertTypes.error, content: `Error saving provider: ${error}` });
    }
    setSavingOrganization(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    await saveOrganization();
  };

  return (
    <div className="max-w-xl px-2">
      {!organizationId && <h2 className="p-1 text-xl font-semibold text-equipsNavyBlue">New Provider Information</h2>}
      <form onSubmit={handleSubmit}>
        <FormFields
          primaryButton="Save"
          loading={isFetching}
          saving={savingOrganization}
          handleChange={({ target: { id, value } }) => {
            if (id === 'typeOfWork') {
              setState((state) => {
                return { ...state, [id]: value.map((o) => o.value) };
              });
            } else {
              setState((state) => {
                return { ...state, [id]: value };
              });
            }
          }}
          fields={[
            {
              fields: [
                {
                  label: 'Service Provider Name',
                  id: 'providerName',
                  value: providerName,
                  placeholder: ' ',
                  required: true,
                  warning: isDuplicateProvider.organizationName ? 'It looks like a Provider with that name already exists' : undefined,
                  duplicateCheckerFunction: ({ value }) => checkAndSetDuplicateProvider('organizationName', value),
                },
                {
                  label: 'Service Provider Phone',
                  id: 'providerPhone',
                  value: providerPhone,
                  placeholder: ' ',
                  required: true,
                  type: fieldTypes.PHONE,
                  warning: isDuplicateProvider.dispatchPhone ? 'It looks like a Provider with that phone already exists' : undefined,
                  duplicateCheckerFunction: ({ value }) => checkAndSetDuplicateProvider('dispatchPhone', value),
                },
                {
                  label: 'Service Provider Payment Preference',
                  id: 'paymentPreference',
                  value: paymentPreferenceOptions.filter((option) => paymentPreference === option.value),
                  type: fieldTypes.SIMPLE_SELECT,
                  placeholder: 'Select payment preference',
                  required: true,
                  options: paymentPreferenceOptions,
                },
                {
                  label: 'Service Provider Source',
                  id: 'providerSource',
                  value: providerSourceOptions.filter((option) => providerSource === option.value),
                  type: fieldTypes.SELECT,
                  placeholder: 'Select provider source',
                  isHalf: false,
                  options: providerSourceOptions,
                  isClearable: true,
                },
                {
                  label: 'Service Provider Email',
                  id: 'providerEmail',
                  value: providerEmail,
                  placeholder: ' ',
                  rule: 'Please enter a valid email address',
                  valid: !providerEmail || validateEmail(providerEmail),
                  warning: isDuplicateProvider.dispatchEmail ? 'It looks like a Provider with that email already exists' : undefined,
                  duplicateCheckerFunction: ({ value }) => checkAndSetDuplicateProvider('dispatchEmail', value),
                  required: paymentPreference === paymentPreferenceEnum?.creditCard?.value,
                },
                {
                  label: "Provider's Address",
                  id: 'providerStreetAddress',
                  value: providerStreetAddress,
                  placeholder: ' ',
                  required: true,
                },
                { label: 'City', id: 'providerCity', value: providerCity, placeholder: ' ', required: true },
                {
                  label: 'State',
                  id: 'providerState',
                  value: providerState || '',
                  type: fieldTypes.STATE_SELECT,
                  isHalf: true,
                  required: true,
                },
                { label: 'Zip Code', id: 'providerZip', value: providerZip, placeholder: ' ', isHalf: true, required: true },
                {
                  label: 'Type of work',
                  id: 'typeOfWork',
                  type: fieldTypes.SELECT,
                  value: failCodeOptions.filter((o) => (typeOfWork || []).includes(o.value as AasFailGroupEnumType)),
                  options: failCodeOptions,
                  placeholder: 'Select fail codes',
                  isMulti: true,
                  required: true,
                  isSearchable: true,
                  isClearable: true,
                },
              ],
            },
          ]}
        />
      </form>
    </div>
  );
};

export default AasCreateEditProvider;

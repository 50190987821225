import React, { useState } from 'react';
import Popover from '@mui/material/Popover';
import Tooltip from '@mui/material/Tooltip';

interface NestedArrayPopoverProps {
  title: React.ReactNode;
  items: any[];
  itemFilter: (item) => any;
  itemMapper: (item) => any;
}

export default function NestedArrayPopover({ title, items, itemFilter, itemMapper }: NestedArrayPopoverProps) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <button type="button" onClick={handlePopoverOpen}>
        <Tooltip title={title}>
          <span>{itemMapper(items.find(itemFilter))}</span>
        </Tooltip>
      </button>
      {open && (
        <Popover
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <ul className="p-2 text-2xs">
            {items?.length === 0 && <li>No other coverage</li>}
            {items?.filter(itemFilter).map((item, i) => (
              <li key={i}>{itemMapper(item)}</li>
            ))}
          </ul>
        </Popover>
      )}
    </div>
  );
}

import React from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';

interface HelperPopperProps {
  placement?: 'bottom' | 'top' | 'left' | 'right';
  open?: boolean;
  anchorEl: any;
  children: React.ReactNode;
  handleClose: () => any;
}

export default function HelperPopper({
  handleClose = () => {},
  open = false,
  placement = 'bottom',
  anchorEl,
  children,
}: HelperPopperProps) {
  return (
    <Popper keepMounted={false} open={open} anchorEl={anchorEl} placement={placement} transition style={{ zIndex: 10000 }}>
      {({ TransitionProps }) => (
        <ClickAwayListener onClickAway={() => handleClose()}>
          <Fade {...TransitionProps} timeout={350}>
            <div className="rounded-lg border bg-white p-3 shadow">{children}</div>
          </Fade>
        </ClickAwayListener>
      )}
    </Popper>
  );
}

import {
  Maybe,
  ElasticsearchSearchTypesEnum,
  fullEquipmentMetadataGraphqlNode,
  queryContracts,
  queryEquipment,
  FloatSearchFilterInputType,
  EquipmentFiltersInput,
  StringSearchFilterInputType,
  gql,
  generateStringTermFilters,
  BooleanSearchFilterInputType,
  fullEquipmentOptionsGraphqlNode,
  fullCoverageOptionsGraphqlNode,
} from '@equips/entities-schema';
import transformTableSortToGraphQLSort from '../../graphql/transformTableSortToGraphQLSort';
import { transformDateRangeSelectAndCustomDateIntoGraphQLQuery } from '../../common/components/Date/DateRangeFilter';
import { toUTCUnixMillisecondFromStringRoundedToStartOfDayInEST } from '../../common/functions/expensiveDateFunctions';
import { toTerms } from '../../common/functions/elasticSearchHelpers';
import { TableFetchArgs } from '../../common/components/DataTable';
import { handleFilterMapEmptyNotEmptySingleSelectCase } from '../../graphql/graphqlHelperFunctions';
import { categoryGraphQlNode } from '../../graphql/queries/categoryQueries';

const convertAnnualizedPriceToCents = (price) => price / 365 / 100;
const equipmentTableQuery = gql`
coveragesMetadata {
  coverageId
  coverageStatus
  onDate
  offDate
  expired
  dailyCoveragePrice
  dailyMaintenanceContractFee
  createdAt
  conditions
  deactivatedAt
}
${fullEquipmentOptionsGraphqlNode}
coverages {
  metadata {
    coverageId
    coverageStatus
    onDate
    offDate
    expired
    dailyCoveragePrice
    createdAt
    conditions
    deactivatedAt
  }
  ${fullCoverageOptionsGraphqlNode}
}
${fullEquipmentMetadataGraphqlNode}
metadata {
  assetPrice
  serviceTotal
  usefulLifeEndDate
  tid
  subclasses
  imageId
  possibleProviderIds
  pmCompliance
  monthsToPmEquipment
  createdAt 
  mainContactUser {
    metadata {
      fullName
      email
    }
  }
  createdByUser { 
    metadata { 
      fullName 
    } 
  }
  location {
    metadata {
      parentLocation { 
        metadata {
          locationDisplayName
        }
      }
    }
  }
  modifiedAt 
  modifiedByUser { 
    metadata { 
      fullName 
    } 
  }
  provider { 
    metadata {
      organizationName
    }
  }
  installationDate
  warrantyOnDate
  warrantyOffDate
  warrantyInfo
  costCenter
  equipsCoveragePmCadence
  clientTags {
    tagId
    entityLabel
    name
    organizationId
  }
  openServiceRequestCount
}
locationMetadata {
  shortId
  locationDisplayName
  locationId
}
locationAddress {
  city
  line1
  line2
  stateUnabbreviated
  zip
}
organizationMetadata {
  organizationName
  organizationId
  clientOrganizationType
  tier
  industry
}
specMetadata {
  ${categoryGraphQlNode}
  model
  manufacturer
  manufacturerEOL
  vendorLimitedSupportDate
}
notes {
  metadata {
    noteId
    message
  }
}
`;

export const agreementFilterTypeOptions = { allEquipment: '', equipmentWithCoverage: 'equipmentWithCoverage' };
const customDateFunctionParams = {
  customDatestringToTimestampFunction: toUTCUnixMillisecondFromStringRoundedToStartOfDayInEST,
};

export const getEquipmentForMainTable = async ({
  sortBy,
  globalFilter = '',
  pageSize = 10,
  pageIndex = 0,
  filterMap,
  includeTotalCount = false,
}: TableFetchArgs) => {
  // If there are any filters or sorting on an array based field,
  //  make sure to apply the special filter saying it also must be effective as of today.
  const includeDateFilterDueToCoverageMetadataSorting = Boolean(
    sortBy?.length > 0 && sortBy?.find((sorting) => sorting?.id?.includes('coveragesMetadata')),
  );

  let onDateFilter = transformDateRangeSelectAndCustomDateIntoGraphQLQuery(filterMap['coveragesMetadata.onDate'], customDateFunctionParams);

  if (includeDateFilterDueToCoverageMetadataSorting && !onDateFilter) {
    onDateFilter = [{ range: { lte: Date.now() } }];
  }
  let offDateFilter = transformDateRangeSelectAndCustomDateIntoGraphQLQuery(
    filterMap['coveragesMetadata.offDate'],
    customDateFunctionParams,
  );

  if (includeDateFilterDueToCoverageMetadataSorting && !offDateFilter) {
    offDateFilter = [{ range: { gte: Date.now() } }];
  }

  let hasChronicIssues: Maybe<BooleanSearchFilterInputType>[] | null | undefined;

  if (filterMap['metadata.hasChronicIssues'] === 'true') {
    hasChronicIssues = [{ term: true }];
  } else if (filterMap['metadata.hasChronicIssues'] === 'false') {
    hasChronicIssues = [{ notTerm: true }];
  }

  let deactivatedAt: Maybe<FloatSearchFilterInputType>[] | null | undefined = filterMap['metadata.deactivatedAt']
    ? [{ exists: true }]
    : undefined;
  let createdAt: Maybe<FloatSearchFilterInputType>[] | null | undefined = transformDateRangeSelectAndCustomDateIntoGraphQLQuery(
    filterMap['metadata.createdAt'],
  );

  let contractIdFilter: Maybe<StringSearchFilterInputType>[] | null | undefined;
  let organizationId = filterMap['metadata.organizationId'];

  let referredByOrgFilter;
  if (filterMap['referredByOrg']?.substring(0, 5) === 'false') {
    referredByOrgFilter = { referringOrganizationId: [{ keyword: [{ notTerm: filterMap['referredByOrg'].substring(5) }] }] };
  } else if (filterMap['referredByOrg']?.substring(0, 4) === 'true') {
    referredByOrgFilter = { referringOrganizationId: [{ keyword: [{ term: filterMap['referredByOrg'].substring(4) }] }] };
  }

  const subclasses = filterMap['metadata.subclasses']?.length > 0 ? filterMap['metadata.subclasses'] : undefined;

  const filter: EquipmentFiltersInput = {
    metadata: {
      ...generateStringTermFilters({
        equipmentId: filterMap['equipmentId'] ? filterMap['equipmentId'].split(',') : undefined,
        shortId: filterMap['metadata.shortId'] ? filterMap['metadata.shortId'].split(',') : undefined,
        providerId: filterMap['metadata.providerId'],
        locationId: filterMap['metadata.locationId'],
        organizationId,
        costCenter: filterMap['metadata.costCenter'],
        specId: filterMap['metadata.specId'],
      }),
      deactivatedAt,
      createdAt,
      ...(filterMap['metadata.equipmentStatus']
        ? { equipmentStatus: [{ keyword: [{ term: filterMap['metadata.equipmentStatus'] }] }] }
        : {}),
      ...(filterMap['metadata.equipmentHealth']
        ? handleFilterMapEmptyNotEmptySingleSelectCase('equipmentHealth', filterMap['metadata.equipmentHealth'], false)
        : {}),
      modifiedAt: transformDateRangeSelectAndCustomDateIntoGraphQLQuery(filterMap['metadata.modifiedAt']),
      ...(filterMap['metadata.createdByUserId']
        ? { createdByUserId: [{ keyword: [{ term: filterMap['metadata.createdByUserId'] }] }] }
        : {}),
      ...(filterMap['metadata.modifiedByUserId']
        ? { modifiedByUserId: [{ keyword: [{ term: filterMap['metadata.modifiedByUserId'] }] }] }
        : {}),
      serviceEndDate: transformDateRangeSelectAndCustomDateIntoGraphQLQuery(filterMap['metadata.serviceEndDate'], customDateFunctionParams),
      usefulLifeEndDate: transformDateRangeSelectAndCustomDateIntoGraphQLQuery(filterMap['metadata.usefulLifeEndDate']),
      installationDate: transformDateRangeSelectAndCustomDateIntoGraphQLQuery(filterMap['metadata.installationDate']),
      warrantyOnDate: transformDateRangeSelectAndCustomDateIntoGraphQLQuery(filterMap['metadata.warrantyOnDate']),
      warrantyOffDate: transformDateRangeSelectAndCustomDateIntoGraphQLQuery(filterMap['metadata.warrantyOffDate']),
      ...(subclasses ? { subclasses: [{ keyword: [{ terms: subclasses }] }] } : {}),
      hasChronicIssues,
      ...(filterMap['metadata.openServiceRequestCount']
        ? { openServiceRequestCount: [{ exists: filterMap['metadata.openServiceRequestCount'] === 'true' ? true : false }] }
        : {}),
      ...(filterMap['metadata.equipsCoveragePmCadence']
        ? handleFilterMapEmptyNotEmptySingleSelectCase('equipsCoveragePmCadence', filterMap['metadata.equipsCoveragePmCadence'], false)
        : {}),
    },
    organizationMetadata: {
      ...(filterMap['referredByOrg'] ? referredByOrgFilter : {}),
      ...(filterMap['organizationMetadata.clientOrganizationType']
        ? { clientOrganizationType: [{ keyword: [{ term: filterMap['organizationMetadata.clientOrganizationType'] }] }] }
        : {}),
      ...(filterMap['organizationMetadata.industry']
        ? { industry: [{ keyword: [{ term: filterMap['organizationMetadata.industry'] }] }] }
        : {}),
    },
    coveragesMetadata: {
      ...(filterMap['coveragesMetadata.annualizedCoveragePrice']
        ? {
            dailyCoveragePrice: [{ range: { gte: convertAnnualizedPriceToCents(filterMap['coveragesMetadata.annualizedCoveragePrice']) } }],
          }
        : {}),
      ...(filterMap['coveragesMetadata.coverageStatus']
        ? { coverageStatus: [{ keyword: [{ term: filterMap['coveragesMetadata.coverageStatus'] }] }] }
        : {}),
      onDate: onDateFilter,
      offDate: offDateFilter,
      contractId: contractIdFilter,
      ...(filterMap['coveragesMetadata.expired'] === 'true'
        ? { expired: [{ term: true }] }
        : filterMap['coveragesMetadata.expired'] === 'false'
        ? { expired: [{ term: false }] }
        : {}),
      ...(filterMap['coveragesMetadata.coverageId'] === 'null' ? { coverageId: [{ keyword: [{ exists: false }] }] } : {}),
    },
    specMetadata: {
      ...(filterMap['specMetadata.specId'] ? { specId: [{ keyword: [{ term: filterMap['specMetadata.specId'] }] }] } : {}),
      categoryId: toTerms<string>(filterMap['specMetadata.categoryId']),
      manufacturerEOL: transformDateRangeSelectAndCustomDateIntoGraphQLQuery(filterMap['specMetadata.manufacturerEOL']),
      vendorLimitedSupportDate: transformDateRangeSelectAndCustomDateIntoGraphQLQuery(filterMap['specMetadata.vendorLimitedSupportDate']),
    },
    tagMetadata: {
      tagId: !filterMap['tagMetadata.tagId']?.includes('none')
        ? toTerms<string>(filterMap['tagMetadata.tagId'])
        : [{ keyword: [{ exists: false }] }],
    },
  };

  const orStatements: EquipmentFiltersInput[] = [];

  if (filterMap['metadata.contractId']) {
    const response = await queryContracts({ contractId: filterMap['metadata.contractId'] });
    const contractMetadata = response?.data?.contracts?.data?.[0]?.metadata;
    organizationId = contractMetadata?.organizationId || '';
    const effectiveDate = contractMetadata?.effectiveDate || Infinity;
    const expirationDate = contractMetadata?.expirationDate || 0;

    const createdBeforeContractEnds = { createdAt: [{ range: { lte: expirationDate } }] };
    const wasDeactivatedDuringTheContract = { deactivatedAt: [{ range: { lte: expirationDate, gte: effectiveDate } }] };
    const equipmentIsActive = { deactivatedAt: [{ notExists: true }] };
    const equipmentHasNoServiceEndDate = { serviceEndDate: [{ notExists: true }] };
    const serviceEndDateIsAfterContractEffectiveDate = { serviceEndDate: [{ range: { gte: effectiveDate } }] };

    orStatements.push({
      metadata: { ...createdBeforeContractEnds, ...wasDeactivatedDuringTheContract, ...equipmentHasNoServiceEndDate },
    });
    orStatements.push({
      metadata: { ...createdBeforeContractEnds, ...wasDeactivatedDuringTheContract, ...serviceEndDateIsAfterContractEffectiveDate },
    });
    orStatements.push({
      metadata: { ...createdBeforeContractEnds, ...equipmentIsActive, ...equipmentHasNoServiceEndDate },
    });
    orStatements.push({
      metadata: { ...createdBeforeContractEnds, ...equipmentIsActive, ...serviceEndDateIsAfterContractEffectiveDate },
    });

    if (filterMap['coverageFilter'] === agreementFilterTypeOptions.equipmentWithCoverage && filter.coveragesMetadata) {
      filter.coveragesMetadata.onDate = [{ range: { gte: effectiveDate, lte: expirationDate } }];
    }
  }

  if (filterMap['sublocationIds']) {
    orStatements.push({ metadata: { locationId: [{ keyword: [{ terms: filterMap['sublocationIds'] }] }] } });
  }

  const filters: EquipmentFiltersInput[] = [];

  if (orStatements.length === 0) {
    filters.push(filter);
  }

  for (const statement of orStatements) {
    filters.push({ ...filter, ...statement, metadata: { ...filter.metadata, ...statement.metadata } });
  }

  const { data } = await queryEquipment(
    {
      search: {
        q: globalFilter,
        size: pageSize,
        from: pageSize * pageIndex,
        searchType: ElasticsearchSearchTypesEnum.PhrasePrefix,
        filters,
        sortBy: transformTableSortToGraphQLSort(sortBy),
        includeTotalCount,
      },
    },
    { query: equipmentTableQuery },
  );

  return { totalCount: data?.equipment?.totalCount, data: data?.equipment?.data ?? [] };
};

import { API } from 'aws-amplify';

export const getHMACHash = async () => {
  try {
    const { data } = await API.get('equips-app', `/intercom-user`, {
      response: true,
    });
    return data.hash;
  } catch (error) {
    console.log(error);
    if (window.Intercom) {
      window.Intercom('boot', {
        app_id: 'p5p8f365',
      });
    }
  }
};

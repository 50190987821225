import React from 'react';

interface StackedColumnProps {
  testId?: string;
  line1: React.ReactNode;
  line2: React.ReactNode;
}

export default function StackedColumn({ testId = '', line1, line2 }: StackedColumnProps) {
  if (!line2) return <span data-testid={testId}>{line1}</span>;

  return (
    <div data-testid={testId}>
      <div className="pb-1 text-sm leading-none">{line1}</div>
      <div className="text-xs leading-none text-gray-500">{line2}</div>
    </div>
  );
}

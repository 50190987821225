import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import { ReactQueryProvider } from './common/components/ReactQueryProvider';
import configureAmplify from './common/functions/configureAmplify';
import configureHeap from './common/functions/configureHeap';
import configureGA from './common/functions/googleAnalytics';
import AppStatusProvider from './common/providers/AppStatusProvider';
import * as serviceWorker from './serviceWorker';
import './styles/tailwind.css';
import './styles/transisions.css';
import './i18n';

configureAmplify();
configureHeap();
configureGA();

const rootElement = document.getElementById('root') as HTMLElement;
const root = createRoot(rootElement);

root.render(
  <Router>
    <AppStatusProvider>
      <ReactQueryProvider>
        <App />
      </ReactQueryProvider>
    </AppStatusProvider>
  </Router>,
);

serviceWorker.unregister();

import React, { useRef } from 'react';

export const badgeTypes = { alert: 1 };

interface BadgeProps {
  children: React.ReactNode;
  badgeType: number;
}

export default function Badge({ children, badgeType }: BadgeProps) {
  const badgeRef = useRef<HTMLSpanElement>(null);
  let color = '';

  if (badgeType === badgeTypes.alert) {
    color = 'border border-white bg-red-500 text-white';
  }

  return (
    <span
      ref={badgeRef}
      className={`${color} absolute right-0 top-0 flex items-center justify-center rounded-full p-1 text-sm leading-none shadow-lg`}
      style={{ transform: `scale(1) translate(30%, -30%)`, height: `${badgeRef?.current?.offsetWidth}px` || '16px' }}
    >
      {children}
    </span>
  );
}

import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { toDateStringFromUnixMillisecondTimestamp } from '../../functions/dateFunctions';

interface DisplayTimeProps {
  timestamp?: number | null;
  emptyFallback?: string;
  customFormatString?: string;
}
export default function DisplayTime({ timestamp = null, emptyFallback = '', customFormatString = '' }: DisplayTimeProps) {
  return (
    <Tooltip
      title={toDateStringFromUnixMillisecondTimestamp(timestamp, {
        customFormatString: customFormatString ? customFormatString : 'MM/DD/YYYY hh:mma',
      })}
    >
      <span>
        {!timestamp
          ? emptyFallback
          : toDateStringFromUnixMillisecondTimestamp(timestamp, { customFormatString: customFormatString ? customFormatString : '' })}
      </span>
    </Tooltip>
  );
}

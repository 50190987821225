import { AasContractPlanType, AasFailGroupEnumType, Maybe, UserMetadata, UserTagsType } from '@equips/entities-schema';

export const devAasOrganiationId = '897b0d6b19144d6d8682ec6a0af185f2';
export const prodAasOrganizationId = 'b8a3884da4a94049ba1c08b95c1bb839';

const devAasDeniedTag = 'bb1e01ec29244fcabd1e761405ac9eb3';
const prodAasDeniedTag = 'bdaad253b78642ec8bd582066810ecf3';

export function getAasOrganizationId() {
  return window.process.env.stage === 'prod' ? prodAasOrganizationId : devAasOrganiationId;
}

export function isAasOrganizationId(organizationId: Maybe<string>) {
  if (organizationId) {
    return organizationId === getAasOrganizationId();
  }
  return false;
}

export function getAasDeniedTagId() {
  return window.process.env.stage === 'prod' ? prodAasDeniedTag : devAasDeniedTag;
}

export function hasAasTagOrOrganizationId(user?: UserMetadata | null) {
  if (!user) return false;
  const hasAasOrganizationId = user.organizationId === getAasOrganizationId();
  const hasAasTag = user.tags?.includes(UserTagsType.Aas);

  return hasAasOrganizationId || hasAasTag;
}

export enum AasContractStatusType {
  INFORCE = 1,
  CANCELLED = 2,
  REJECTED = 3,
  EXPIRED = 5,
  IMPORT = 6,
  'BACK OUT' = 7,
  SUSPENDED = 8,
  ACTIVE = 10,
  'PENDING CANCEL' = 11,
  'CANCEL ACTIVE' = 13,
  PREPAID = 14,
  PAYEE = 'PAY',
}

/** Translate AAS plan type code */
export const getAasPlanType = (planType: Maybe<AasContractPlanType>) => {
  let plan: string;

  switch (planType) {
    case AasContractPlanType.Mhomesys:
    case AasContractPlanType.Homesys:
      plan = 'System';
      break;
    case AasContractPlanType.Mhomeapp:
    case AasContractPlanType.Homeapp:
      plan = 'Appliance';
      break;
    case AasContractPlanType.Mhome:
    case AasContractPlanType.Home:
      plan = 'Home';
      break;
    case AasContractPlanType.Homer:
      plan = 'Home (R)';
      break;
    case AasContractPlanType.Homerapp:
      plan = 'Appliance (R)';
      break;
    case AasContractPlanType.Homersys:
      plan = 'System (R)';
      break;
    case AasContractPlanType.Lcmhome:
      plan = 'Home (LC)';
      break;
    case AasContractPlanType.Lcmhomeapp:
      plan = 'Appliance (LC)';
      break;
    case AasContractPlanType.Lcmhomesys:
      plan = 'System (LC)';
      break;
    default:
      plan = '';
  }

  return plan;
};

export const AasFailGroupToTextMap = {
  [AasFailGroupEnumType.AcAndHeating]: 'A/C & HEATING',
  [AasFailGroupEnumType.Appliances]: 'APPLIANCES',
  [AasFailGroupEnumType.CeilingFan]: 'CEILING FAN',
  [AasFailGroupEnumType.DoorBell]: 'DOOR BELL',
  [AasFailGroupEnumType.Electrical]: 'ELECTRICAL',
  [AasFailGroupEnumType.PlumbingSystem]: 'PLUMBING SYSTEM',
  [AasFailGroupEnumType.Roofing]: 'ROOFING',
  [AasFailGroupEnumType.SepticSystem]: 'SEPTIC SYSTEM',
  [AasFailGroupEnumType.ServiceFee]: 'SERVICE FEE',
  [AasFailGroupEnumType.SmokeDetector]: 'SMOKE DETECTOR',
  [AasFailGroupEnumType.SolarEnergySystem]: 'SOLAR ENERGY SYSTEM',
  [AasFailGroupEnumType.SpaAndPool]: 'SPA & POOL',
  [AasFailGroupEnumType.WaterHeater]: 'WATER HEATER',
  [AasFailGroupEnumType.WaterSoftener]: 'WATER SOFTENER',
  [AasFailGroupEnumType.WellPump]: 'WELL PUMP',
  [AasFailGroupEnumType.WindEnergySystem]: 'WIND ENERGY SYSTEM',
  [AasFailGroupEnumType.WineRoom]: 'WINE ROOM',
};

export const servicePowerToArmisFailCodes = {
  'H-AP-0003': { code: 'H-AP-0003', description: 'DRY' },
  'H-AP-0004': { code: 'H-AP-0004', description: 'WASH' },
  'H-AP-0005': { code: 'H-AP-0005', description: 'DW' },
  'H-AP-0009': { code: 'H-AP-0009', description: 'MICRO' },
  'H-AP-0010': { code: 'H-AP-0010', description: 'RNG' },
  'H-AP-0011': { code: 'H-AP-0011', description: 'REF' },
  'H-AP-0014': { code: 'H-AP-0014', description: 'FRZ' },
  'H-AP-0019': { code: 'H-AP-0019', description: 'RNGHD' },
  'H-AP-0020': { code: 'H-AP-0020', description: 'SEAL' },
};

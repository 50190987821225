import React from 'react';
import className from 'classnames';
import Skeleton from '../Loaders/Skeleton';

interface InputLoaderProps {
  fullWidth?: boolean;
}

export default function InputLoader({ fullWidth = true }: InputLoaderProps) {
  return (
    <div
      className={className({
        'leading-tightest rounded text-4xl': true,
        'w-full': fullWidth,
        'w-1/2': !fullWidth,
      })}
    >
      <Skeleton />
    </div>
  );
}

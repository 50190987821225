import React from 'react';

interface ReactSelectRequiredInputProps {
  isValid?: any;
}

export default function ReactSelectRequiredInput({ isValid = false }: ReactSelectRequiredInputProps) {
  return (
    <input
      data-testid="requiredInput"
      autoComplete="off"
      className="absolute inset-0 w-full"
      tabIndex={-1}
      style={{ zIndex: '-1' }}
      onChange={() => ({})}
      value={isValid ? 'valid' : ''}
      required
    />
  );
}

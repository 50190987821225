import React, { useState } from 'react';
import { PlaylistAdd, PlaylistAddCheckOutlined } from '@mui/icons-material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  AasChargeStatusType,
  AcceptedFileExtensions,
  ChargeAdjudicationResultType,
  ChargeDescriptionEnum,
  ChargeTypeType,
  ClientOrganizationEnumType,
  FileMetadataPostInput,
  patchInvoice,
  PayeeType,
  postCharge,
  postFile,
  postInvoice,
  queryServiceRequests,
} from '@equips/entities-schema';
import IconButton from '../Buttons/IconButton';
import SimpleModal, { simpleModalTypes } from '../Modal/SimpleModal';
import Button from '../Buttons/Button';
import { AlertTypes, useAlert } from '../Alerts/AlertContext';
import { useAuth } from '../../auth/AuthContext';
import { NEW } from '../../../screens/invoices/invoiceStatuses';
import DropDown from '../DropDown';
import { NoteAttachmentToInvoice } from './NoteAttachment';

enum CreateType {
  none = 'none',
  createInvoice = 'createInvoice',
  createQuote = 'createQuote',
  addToInvoice = 'addToInvoice',
}

const MODAL_TEXT = {
  [CreateType.createInvoice]: {
    title: 'Are you sure you want to create a new invoice with this file?',
    saveButton: 'Save New Invoice',
  },
  [CreateType.createQuote]: {
    title: 'Are you sure you want to create a new estimate with this file?',
    saveButton: 'Save New Estimate',
  },
  [CreateType.addToInvoice]: {
    title: 'Are you sure you want to add this file to an existing invoice?',
    saveButton: 'Save Invoice',
  },
};

type StateType = { createType: CreateType; existingInvoiceId: string };

const DEFAULT_STATE = {
  createType: CreateType.none,
  existingInvoiceId: '',
};

type CreateInvoiceModalProps = {
  /** Attachment file storage location */
  location?: string;
  /** Attachment name */
  name?: string;
} & NoteAttachmentToInvoice;

export const CreateInvoiceModal = ({ location, parentId, serviceRequestId, name, createdAt, noteId }: CreateInvoiceModalProps) => {
  const { auth, isAasUser, clientOrganizationType, internalUsers } = useAuth();
  const showAlert = useAlert();
  const queryClient = useQueryClient();
  const isCoverageInternalUser =
    clientOrganizationType === ClientOrganizationEnumType.EquipsCoverage &&
    auth?.authorizationRole &&
    internalUsers.includes(auth?.authorizationRole);

  const { data, refetch } = useQuery(
    ['getServiceRequestsForInvoiceModal', serviceRequestId],
    () =>
      queryServiceRequests(
        {
          serviceRequestId,
        },
        {
          query: `
        organizationMetadata {
          invoiceSettings {
            deductibleAmount
          }
        }
        invoices {
          invoiceId
          shortId
          title
          deactivatedAt
          fileId
          file {
            metadata {
              fileStorageLocation
            }
          }
          quoteFile {
            metadata {
              fileStorageLocation
            }
          }
        }
        metadata {
          shortId
          requestedProviderId
          organization {
            metadata {
              organizationId
            }
          }
          aasContract{
            metadata{
              planDeductible
            }
          }
        }
        incidents {
          metadata {
            incidentId
            equipment {
              metadata {
                serialNumber
              }
            }
          }
        }`,
        },
      ),
    { enabled: !!serviceRequestId },
  );

  const serviceRequest = data?.data?.serviceRequests?.data?.[0] || {};

  const activeInvoices = serviceRequest.invoices?.filter((invoice) => !invoice?.deactivatedAt) || [];

  const shortId = serviceRequest?.metadata?.shortId || '';
  const incidentId = serviceRequest?.incidents?.[0]?.metadata?.incidentId;
  const organization = serviceRequest?.metadata?.organization;
  const organizationId = organization?.metadata?.organizationId;
  const planDeductibleInCents = (serviceRequest?.metadata?.aasContract?.metadata?.planDeductible ?? 0) * -100;
  const deductibleAmount =
    (isAasUser ? planDeductibleInCents : serviceRequest?.organizationMetadata?.invoiceSettings?.deductibleAmount) || 0;
  const providerOrganizationId = serviceRequest?.metadata?.requestedProviderId || '';
  const serialNumber = serviceRequest?.incidents?.[0]?.metadata?.equipment?.metadata?.serialNumber;
  const fileExtension = name?.split('.')?.[1] || '';
  const invoices = data?.data?.serviceRequests?.data?.[0]?.invoices;
  const foundDuplicateInvoice = invoices?.some(
    (invoice) =>
      invoice?.file?.metadata?.fileStorageLocation === location || invoice?.quoteFile?.metadata?.fileStorageLocation === location,
  );

  const [state, setState] = useState<StateType>(DEFAULT_STATE);

  const { mutate: createNewInvoice, isLoading: isSaving } = useMutation(
    async () => {
      let invoiceId = state.existingInvoiceId;

      if (!invoiceId) {
        // Create new invoice record
        const newInvoice = await postInvoice({
          metadata: {
            invoiceStatus: NEW,
            ...(isAasUser && { invoiceServiceRequestId: serviceRequestId }),
            serviceRequestNumber: shortId,
            clientOrganizationId: organizationId,
            ...(providerOrganizationId && { providerOrganizationId }),
            ...(isCoverageInternalUser && { serialNumber: serialNumber, receivedAt: createdAt }),
          },
        });

        invoiceId = newInvoice?.data?.post?.invoiceId ?? '';
        // Only apply this for AAS and on the first invoice.
        if (activeInvoices.length === 0 && isAasUser) {
          await postCharge({
            metadata: {
              invoiceId: invoiceId,
              incidentId: incidentId,
              clientOrganizationId: organizationId,
              description: ChargeDescriptionEnum.DeductibleCredit,
              aasChargeStatus: AasChargeStatusType.Approved,
              chargeType: ChargeTypeType.ServiceCharge,
              amountInCents: deductibleAmount,
              payeeType: PayeeType.Customer,
              adjudicationResult: ChargeAdjudicationResultType.Approved,
            },
          });
        }
      }

      // Create new file record that points to the same file as the attachment
      const fileMetadata: FileMetadataPostInput = {
        fileExtension: fileExtension as AcceptedFileExtensions,
        parentId: parentId,
        fileStorageLocation: location,
        fileName: name,
      };
      const file = await postFile({ metadata: fileMetadata });
      const fileId = file?.data?.post?.fileId;
      const fileFieldName = state.createType === CreateType.createQuote ? 'quoteFile' : 'file';

      // Patch invoice to point to new file record
      await patchInvoice({ invoiceId, metadata: { [`${fileFieldName}Id`]: fileId } });
    },
    {
      onSuccess: () => {
        showAlert({ type: AlertTypes.success, content: 'Successfully created a new invoice' });
        setState(DEFAULT_STATE);
        refetch();

        if (isAasUser) {
          queryClient.invalidateQueries(['getAasClaimForView', serviceRequestId]);
          queryClient.invalidateQueries(['TaskDetail', 'ARMISClaimNotes', noteId, 'note'], { exact: true });
        }

        isCoverageInternalUser && queryClient.invalidateQueries(['ViewServiceRequest', serviceRequestId]);
      },
      onError: () => showAlert({ type: AlertTypes.error, content: 'An error occurred while saving the new invoice.  Please try again.' }),
    },
  );

  const createInvoiceButton = (
    <IconButton
      iconClassName={'text-blue-600'}
      Icon={PlaylistAdd}
      size="small"
      text="Create new invoice"
      onClick={() => {
        setState({ createType: CreateType.createInvoice, existingInvoiceId: '' });
      }}
    />
  );

  const activeInvoicesWithoutInvoiceFile = activeInvoices?.filter((invoice) => !invoice?.fileId);

  const addToExistingInvoiceComponents = activeInvoicesWithoutInvoiceFile
    ? activeInvoicesWithoutInvoiceFile?.map((invoice) => {
        return {
          Component: (
            <>
              Add to {invoice?.shortId} {invoice?.title || '(Untitled invoice)'}
            </>
          ),
          action: () => {
            setState({ existingInvoiceId: invoice?.invoiceId ?? '', createType: CreateType.addToInvoice });
          },
        };
      })
    : [];

  const createInvoiceButtonForAas = (
    <DropDown
      actions={[
        {
          Component: <>Create new Estimate</>,
          action: () => {
            setState({ createType: CreateType.createQuote, existingInvoiceId: '' });
          },
        },
        {
          Component: <>Create new Invoice</>,
          action: () => {
            setState({ createType: CreateType.createInvoice, existingInvoiceId: '' });
          },
        },
        ...addToExistingInvoiceComponents,
      ]}
    >
      <IconButton iconClassName={'text-blue-600'} Icon={PlaylistAdd} size="small" text="Create new invoice" />
    </DropDown>
  );

  return (
    <>
      {!foundDuplicateInvoice ? (
        isAasUser ? (
          createInvoiceButtonForAas
        ) : (
          createInvoiceButton
        )
      ) : (
        <IconButton
          iconClassName={'text-green-600'}
          Icon={PlaylistAddCheckOutlined}
          size="small"
          text="Invoice already created from this file"
        />
      )}
      {state.createType !== CreateType.none && (
        <SimpleModal
          size="xs"
          modalType={simpleModalTypes.info}
          isOpen={true}
          handleClose={() => setState(DEFAULT_STATE)}
          title={MODAL_TEXT[state.createType].title}
        >
          <div className="ml-10 mt-4 flex justify-start gap-5">
            <Button className="ml-4" green rounded="rounded-xl" onClick={() => createNewInvoice()} loading={isSaving}>
              {MODAL_TEXT[state.createType].saveButton}
            </Button>
            <Button danger rounded="rounded-xl" onClick={() => setState(DEFAULT_STATE)}>
              No
            </Button>
          </div>
        </SimpleModal>
      )}
    </>
  );
};

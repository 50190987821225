import React from 'react';
import Loader from 'react-spinners/PulseLoader';
import './Switch.css';

interface SwitchProps {
  id: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  checked: boolean;
  className?: string;
  label?: string;
  loading?: boolean;
  tiny?: boolean;
  large?: boolean;
}

export default function Switch({
  id,
  loading = false,
  label = '',
  onChange,
  checked = false,
  tiny = false,
  large = false,
  className = '',
  ...restOfProps
}: SwitchProps) {
  return (
    <div className={className} {...restOfProps}>
      {loading ? (
        <Loader size={8} color="#718096" />
      ) : (
        <>
          <input
            data-testid={id}
            type="checkbox"
            className={`switch ${tiny ? 'switch-sm' : ''} ${large ? 'switch-lg' : ''}`}
            id={id}
            onChange={onChange}
            checked={checked}
          />
          <label data-testid={`${id}Label`} className="text-sm text-gray-700" htmlFor={id}>
            {label}
          </label>
        </>
      )}
    </div>
  );
}

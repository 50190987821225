import React from 'react';
import classNames from 'classnames';

interface FormContainerProps {
  children: React.ReactNode;
}

export const FormContainer = ({ children }: FormContainerProps) => {
  return <div className="my-1 flex w-full flex-wrap justify-between">{children}</div>;
};

interface FormGroupProps {
  children: React.ReactNode;
  fullWidth?: boolean;
  oneThird?: boolean;
  className?: string;
}

export default function FormGroup({ fullWidth = false, oneThird = false, children, className = '', ...restOfProps }: FormGroupProps) {
  return (
    <fieldset
      className={classNames({
        'w-full py-2': true,
        [`md:w-1/${oneThird ? '3' : '2'} -mx-1 px-1`]: !fullWidth,
        [className]: true,
      })}
      {...restOfProps}
    >
      {children}
    </fieldset>
  );
}

import React from 'react';
import { Link } from 'react-router-dom';

interface BreadCrumbsProps {
  items: { text: string; to: any }[];
  className?: string;
}

export default function BreadCrumbs({ items, className = '' }: BreadCrumbsProps) {
  const lastItemIndex = items.length - 1;

  return (
    <nav className={`flex items-center text-sm font-medium leading-5 ${className}`}>
      {items.map(({ text, to }, i) => (
        <div key={text} className="flex">
          <Link
            to={to}
            className="text-gray-500 transition duration-150 ease-in-out hover:text-gray-800 focus:underline focus:outline-none"
          >
            {text}
          </Link>
          {i !== lastItemIndex && (
            <svg className="mx-2 h-5 w-5 shrink-0 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
              <path
                fillRule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clipRule="evenodd"
              ></path>
            </svg>
          )}
        </div>
      ))}
    </nav>
  );
}

import React, { useState, lazy } from 'react';
import Search from '@mui/icons-material/Search';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import { useHotkeys } from 'react-hotkeys-hook';

const SearchBar = lazy(() => import('./SearchBar'));

export default function OmniSearch() {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  useHotkeys('esc', (e) => {
    e.preventDefault();
    setIsOpen(false);
  });

  useHotkeys('/', (e) => {
    e.preventDefault();
    setIsOpen(true);
  });

  return (
    <div data-testid="omniSearch" className="search-form flex items-center">
      {isOpen ? (
        <>
          <SearchBar data-testid="omniSearchBar" closeSearchBar={() => setIsOpen(false)} />
        </>
      ) : (
        <>
          <Tooltip title={t('clickOrPressKeyToSearch', { key: '/' })}>
            <button
              className="cursor-pointer rounded-xl px-3 py-1 text-lg text-white hover:bg-gray-900"
              onClick={(event) => {
                event.preventDefault();
                setIsOpen(true);
              }}
              type="button"
              data-testid="omniSearchButtonTrigger"
            >
              <Search />
            </button>
          </Tooltip>
        </>
      )}
    </div>
  );
}

import React from 'react';
import Close from '@mui/icons-material/Close';
import { AlertProvider } from './AlertContext';

const toastify = () => import(/* webpackChunkName: "toastify" */ './toastify');

const CloseButton = ({ closeToast }: { closeToast?: () => any }) => (
  <div className="flex items-center">
    <button type="button" className="text-gray-700" onClick={() => closeToast?.()}>
      <Close fontSize="small" />
    </button>
  </div>
);

export default function MainAlert({ children }: { children: React.ReactNode }) {
  const showAlert = ({ type, content, action, autoClose = true, ...restOfProps }) => {
    const undoAble = async (content) => {
      const toast = await toastify();
      toast.success(content, { autoClose, closeButton: <CloseButton />, ...restOfProps });
    };

    const success = async (content) => {
      const toast = await toastify();
      toast.success(content, { autoClose, closeButton: <CloseButton />, ...restOfProps });
    };

    const warning = async (content) => {
      const toast = await toastify();
      toast.warn(content, { autoClose, closeButton: <CloseButton />, ...restOfProps });
    };

    const error = async (content) => {
      const toast = await toastify();
      toast.error(content, { autoClose, closeButton: <CloseButton />, ...restOfProps });
    };

    const dismiss = async () => {
      const toast = await toastify();
      toast.dismiss();
    };

    switch (type) {
      case 'dismiss':
        dismiss();
        break;
      case 'undo':
        undoAble(
          <div data-testid="mainAlertUndoCase">
            {content || 'Successfully removed'}
            <div className="py-2">
              <button
                data-testid="mainAlertUndoAction"
                className="rounded-lg border border-gray-300 px-2 py-1 text-xs"
                type="button"
                onClick={() => action()}
              >
                Undo
              </button>
            </div>
          </div>,
        );
        break;
      case 'warning':
        warning(
          <div data-testid="mainAlertWarningCase">
            <h4>Warning</h4>
            {content}
          </div>,
        );
        break;
      case 'error':
      case 'negative':
        console.error(content);
        error(
          <div data-testid="mainAlertErrorCase">
            <h4>Error</h4>
            {content}
          </div>,
        );
        break;
      case 'success':
      default:
        success(
          <div data-testid="mainAlert">
            <h4>Success</h4>
            {content}
          </div>,
        );
    }
  };

  return <AlertProvider value={showAlert}>{children}</AlertProvider>;
}
